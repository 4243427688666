<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <action-icon
      :action-type="action.type"
      class="action-icon"
      :class="`action-style-${action.type.toLowerCase()}`"
    ></action-icon>
    <div class="action-name">{{ action.name }}</div>
    <component
      :is="actionStat(action)"
      class="action-stats"
      :stats="action.stats"
      @click.native="onStatsClick"
    />
    <div class="action-options">
      <div class="action-option-yes">Yes</div>
      <div class="action-option-no">No</div>
    </div>
    <div v-if="!workflow.isSnapshot" class="action-overlay">
      <icon
        v-if="!readOnly"
        v-tooltip="'Delete'"
        class="action-delete"
        glyph="trash"
        @click="deleteAction"
      />
      <icon
        v-if="!readOnly"
        v-tooltip="'Copy'"
        class="action-copy"
        glyph="copy"
        @click="copyAction"
      />
      <icon
        v-tooltip="'Reporting'"
        class="action-reporting"
        :class="{ 'icon-hidden': !singleSelect }"
        glyph="bar-chart"
        @click="showReporting"
      />
    </div>
  </div>
</template>
<script>
import { CELL_SIZE } from '../GridSize'
import Action from './AbstractAction'

export default {
  extends: Action,
  data() {
    return {
      icon: 'question-mark'
    }
  },

  successorAnchors: {
    yes: [0.25, 1, 0, 1],
    no: [0.75, 1, 0, 1]
  },

  successorOffset: {
    yes: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 4
    },
    no: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 4
    }
  }
}
</script>
<style scoped lang="sass">
.action
    height: 110px

.action-option-yes,
.action-option-no
    width: 50%
    float: left
    padding: 12px 0
    text-transform: uppercase
    color: #fff
    text-align: center

.action-option-yes
    background-color: #00975a

.action-option-no
    background-color: #e83d3d

.action-overlay
    border-radius: 0 5px 0 0
</style>
