export default {
  computed: {
    isBetssonAvailable() {
      return this.$store.getters.isFeatureEnabled(
        'nationalExclusionCheckBetsson'
      )
    },
    isOasisAvailable() {
      return this.$store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
    },
    betssonOptions() {
      return [
        {
          value: 0,
          title: 'Marketing',
          titleSelected: 'Marketing (Techsson)'
        },
        {
          value: 1,
          title: 'Service',
          titleSelected: 'Service (Techsson)'
        },
        {
          value: 2,
          title: 'General',
          titleSelected: 'General (Techsson)'
        }
      ]
    },
    oasisOptions() {
      return [
        {
          value: 10,
          title: 'Marketing',
          titleSelected: 'Marketing (Oasis)'
        },
        {
          value: 11,
          title: 'Marketing + Bonus',
          titleSelected: 'Marketing + Bonus (Oasis)'
        }
      ]
    },
    isExclusionEnabled() {
      return (
        this.$store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
        this.$store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
      )
    }
  },
  methods: {
    getNecLabelByValue(value) {
      let categories = []
      if (this.isBetssonAvailable) {
        categories = this.betssonOptions
      }
      if (this.isOasisAvailable) {
        categories = [...categories, ...this.oasisOptions]
      }
      let category = categories.filter((c) => {
        return c.value === parseInt(value)
      })
      if (category[0]) {
        if (this.isBetssonAvailable && this.isOasisAvailable) {
          return category[0].titleSelected
        } else {
          return category[0].title
        }
      }
    }
  }
}
