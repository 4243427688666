<template>
  <div>
    <span>Profile</span>
    <span v-if="isFieldValid">
      field
      <span class="criteria-field-name">'{{ field }}'</span>
    </span>
    <span v-else class="invalid-text">missing field</span>
    <span>{{ negatorText }}</span>
    <span :is="conditionCmp" :condition-args="conditionArgs"></span>
  </div>
</template>
<script>
import MissingCondition from './MissingCondition'

export default {
  props: ['field', 'negator', 'condition', 'conditionArgs'],

  computed: {
    isFieldValid() {
      return this.field !== ''
    },

    negatorText: function () {
      return !this.negator ? 'is' : 'is not'
    },

    conditionCmp() {
      try {
        return require(`./Profile/${this.condition}`).default
      } catch (e) {}

      return MissingCondition
    }
  }
}
</script>
