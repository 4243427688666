<template>
  <select2
    v-model="selectedTimezone"
    enable-search
    placeholder="Select Timezone"
    @input="$emit('input', selectedTimezone)"
  >
    <optgroup v-if="bespokeOptions" label="Other">
      <option v-for="(label, value) in bespokeOptions" :value="value">
        {{ label }}
      </option>
    </optgroup>
    <optgroup label="Timezones">
      <option v-for="timezone in timezones" :value="timezone">
        {{ timezone }}
      </option>
    </optgroup>
  </select2>
</template>
<script>
import timezones from '@/components/timezonesEnum'
export default {
  props: {
    value: {
      type: String
    },

    bespokeOptions: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      selectedTimezone: this.value || 'profile',
      timezones
    }
  }
}
</script>
