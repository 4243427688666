<template>
  <div class="criteria-options">
    <input
      v-model="value.field"
      class="form-control field"
      type="text"
      placeholder="Property Name"
    />

    <select2 v-model="negatorValue" class="negator">
      <option value="is">IS</option>
      <option value="isnot">IS NOT</option>
    </select2>

    <select2
      v-model="value.condition"
      title="Nothing Selected"
      class="condition"
    >
      <optgroup label="Text">
        <option title="Text: Equal to" value="TextEquals">Equal to</option>
        <option title="Text: Containing" value="TextContains">
          Containing
        </option>
        <option title="Text: Beginning with" value="TextBeginsWith">
          Beginning with
        </option>
        <option title="Text: Ending with" value="TextEndsWith">
          Ending with
        </option>
      </optgroup>
      <optgroup label="Number">
        <option title="Number: Equals" value="NumberEquals">Equals</option>
        <option title="Number: Less than" value="NumberLessThan">
          Less than
        </option>
        <option title="Number: Greater than" value="NumberGreaterThan">
          Greater than
        </option>
        <option title="Number: Between" value="NumberBetween">Between</option>
      </optgroup>
      <optgroup label="Date">
        <option title="Date: On" value="DateOn">On</option>
        <option title="Date: Before" value="DateBefore">Before</option>
        <option title="Date: After" value="DateAfter">After</option>
        <option title="Date: Between" value="DateBetween">Between</option>
      </optgroup>
      <optgroup label="Field">
        <option title="Field: Empty" value="FieldEmpty">Empty</option>
      </optgroup>
      <optgroup label="Boolean">
        <option title="Boolean: True" value="BooleanYes">True</option>
        <option title="Boolean: False" value="BooleanNo">False</option>
      </optgroup>
    </select2>

    <div
      :is="conditionCmp"
      v-model="value.conditionArgs"
      class="condition-args"
    ></div>
  </div>
</template>
<script>
import { ProfileAttributes } from 'maxautomation-xp-components'
import Select2 from '@/components/Select2'

export default {
  components: {
    ProfileAttributes,
    Select2
  },
  props: ['value'],

  computed: {
    conditionCmp() {
      try {
        return require(`./Profile/${this.value.condition}`).default
      } catch (e) {}

      return null
    },

    negatorValue: {
      get() {
        return this.value.negator ? 'isnot' : 'is'
      },
      set(val) {
        this.value.negator = val !== 'is'
      }
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
  }
}
</script>
<style lang="sass" scoped>

.field
    ::v-deep
        .multiselect__tags
            max-height: 34px
            padding: 6px 30px 6px 12px

            span.multiselect__single
                max-height: 20px
                display: block
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
                padding: 0

        .multiselect__select
            height: 34px

        .multiselect__select:before /* down arrow */
            border-width: 4px 4px 0
</style>
