import Vue from 'vue'

const props = {}

export default {
  register(newProps) {
    Object.assign(props, newProps)
  },

  show(cmp, data) {
    let el = document.createElement('div')
    document.body.appendChild(el)

    const config = {
      name: 'Dialog',
      el,
      functional: true,
      render(createElement) {
        return createElement(cmp, {
          props: data
        })
      },
      destroyed() {
        this.$el.parentElement.removeChild(this.$el)
      }
    }

    Object.assign(config, props)

    return new Vue(config)
  }
}
