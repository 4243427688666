<template>
  <div style="padding: 20px">
    <label>SMS Delivery Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="sms" />
        <div class="stats-bar-item-title">Sent:</div>
        <div class="stats-bar-item-number">
          {{ smsSentTotal | number }}
          ({{ smsSentTotal | percentageOf(smsArrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      :chart-data="chartData"
      title="Total Number of SMS Sent"
      title-class="action-color-messages"
      yLabel="Number of Profiles"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  props: [
    'smsArrivedTotal',
    'smsSentTotal',

    'dateRange',
    'smsSentStats',
    'smsUnsubscribesStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  computed: {
    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Sent',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.smsSentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.smsSentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
