<template>
  <div class="toolbox-large-decision">
    <div class="form-group" style="margin-bottom: 25px; display: flex">
      <div style="width: 50%">
        <label class="control-label">Decision Title:</label>
        <input v-model="title" class="form-control" @change="changeTitle" />
      </div>
      <div style="width: 50%" class="button-group">
        <button
          v-if="hasNextKey"
          :disabled="isLoading"
          class="btn btn-default spacing"
          @click="moveDown()"
        >
          <icon glyph="down-arrow" />
          Move group down
        </button>
        <button
          v-if="successorKey !== 'a'"
          :disabled="isLoading"
          class="btn btn-default spacing"
          @click="moveUp()"
        >
          <icon glyph="up-arrow" />
          Move group up
        </button>
      </div>
    </div>

    <button class="btn btn-default" @click="toggleType">Switch Logic</button>

    <div v-show="!validator.length" style="padding: 10px 0">No criteria</div>

    <div v-for="(group, index) in validator" :key="index">
      <div class="group">
        <div v-for="(criteria, index) in group" class="criteria form-inline">
          <div style="padding: 15px 10px">
            <select2
              :value="criteriaTypeValue(criteria)"
              class="criteria-type"
              @input="onCriteriaTypeChange(criteria, $event)"
            >
              <option value="profile">Profile Attribute</option>
              <option value="message" :disabled="!isMessageOptionAvailable">
                Message
              </option>
              <option value="segment">Segment</option>
              <option value="event">Event Property</option>
              <option value="date">Current Date</option>
            </select2>
          </div>

          <component
            :is="criteriaTypeValue(criteria)"
            v-model="group[index]"
            class="criteria-options"
            :features="features"
          ></component>

          <div class="criteria-action">
            <template v-if="index == group.length - 1">
              <span
                v-tooltip:bottom="'Add Criteria'"
                class="criteria-action-button"
                @click="addCriteria(group)"
              >
                <icon glyph="add"></icon>
              </span>
            </template>

            <span
              v-if="group.length > 1"
              v-tooltip:bottom="'Remove Criteria'"
              class="criteria-action-button criteria-action-remove"
              @click="removeCriteria(criteria, group)"
            >
              <icon glyph="trash"></icon>
            </span>

            <span
              v-if="group.length == 1"
              v-tooltip:bottom="'Remove Group'"
              class="criteria-action-button criteria-action-remove"
              @click="removeGroup(group)"
            >
              <icon glyph="trash"></icon>
            </span>
          </div>

          <div v-show="index < group.length - 1" class="logic logic-criteria">
            {{ criteriaLogicText }}
          </div>
        </div>
      </div>

      <div v-show="index < validator.length - 1" class="logic logic-group">
        {{ groupLogicText }}
      </div>
    </div>
    <button class="btn btn-default logic" @click="addGroup">
      <icon glyph="add" />
      {{ groupLogicText }}
    </button>

    <button
      v-if="Object.keys(action.successors).length > 2"
      :disabled="isLoading"
      class="btn btn-default"
      style="float: right"
      @click="confirmDelete"
    >
      <icon glyph="trash" />
      Remove group {{ successorKey.toUpperCase() }}
    </button>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'
import Profile from '../Decision/Profile'
import Message from '../Decision/Message'
import Segment from '../Decision/Segment'
import Event from '../Decision/Event'
import Date from '../Decision/Date'
import moment from 'moment'
import alert from '@/libs/Alert'

export default {
  components: {
    Select2,
    Profile,
    Message,
    Segment,
    Event,
    Date
  },

  inject: ['workflowApi', 'eventBus'],
  props: ['action', 'readOnly', 'workflow', 'successorKey'],

  data() {
    let data = {
      validatorType: this.action.validators[this.successorKey].validatorType,
      validator: JSON.parse(
        JSON.stringify(this.action.validators[this.successorKey].validator)
      ),
      title: this.action.config.successorTitles[this.successorKey],
      key: null,
      isLoading: false
    }
    if (data.validator.length === 0) {
      data.validator = [[this.getDefaultProfile()]]
    }
    return data
  },

  computed: {
    groupLogicText() {
      return this.validatorType === 'oneOf' ? 'or' : 'and'
    },

    criteriaLogicText() {
      return this.validatorType === 'oneOf' ? 'and' : 'or'
    },

    isOneOf() {
      return this.validatorType === 'oneOf'
    },

    isAllOf() {
      return this.validatorType === 'allOf'
    },

    hasNextKey() {
      const currentIndex = Object.keys(this.action.successors).indexOf(
        this.successorKey
      )
      const nextKey = Object.keys(this.action.successors)[currentIndex + 1]

      return !(!nextKey || nextKey === 'else')
    },

    nonEmailMessages() {
      const nonEmailTypes = ['Push', 'Sms']
      return this.workflow.actions.filter((a) => nonEmailTypes.includes(a.type))
        .length
    },

    isEmailOpenTrackingEnabled() {
      return this.workflow.features.email_open_tracking != undefined
        ? this.workflow.features.email_open_tracking
        : true
    },

    isEmailClickTrackingEnabled() {
      return this.workflow.features.email_click_tracking != undefined
        ? this.workflow.features.email_click_tracking
        : true
    },

    isMessageOptionAvailable() {
      return (
        this.nonEmailMessages > 0 ||
        this.isEmailOpenTrackingEnabled ||
        this.isEmailClickTrackingEnabled
      )
    },

    features() {
      return {
        email_open_tracking: this.isEmailOpenTrackingEnabled,
        email_click_tracking: this.isEmailClickTrackingEnabled
      }
    }
  },

  created() {
    this.eventBus.$on('multidecision-endpoint-update-finish', (id) => {
      if (id === this.action.id) {
        this.isLoading = false
        sessionStorage.removeItem(this.action.id + '-successors')
      }
    })

    this.eventBus.$on('multidecision-successor-change-disable-all', (id) => {
      if (id === this.action.id) {
        this.isLoading = true
      }
    })
  },

  beforeDestroy() {
    this.action.validators[this.successorKey].validatorType = this.validatorType
    this.action.validators[this.successorKey].validator = this.validator
    this.action.config.successorTitles[this.successorKey] = this.title
  },

  methods: {
    changeTitle(event) {
      const config = { ...this.action.config }
      config.successorTitles[this.successorKey] = event.target.value
      this.workflowApi.updateAction(this.action.id, { config })
    },

    toggleType() {
      if (this.validatorType === 'allOf') {
        this.validatorType = 'oneOf'
      } else {
        this.validatorType = 'allOf'
      }
    },

    criteriaTypeValue(criteria) {
      return criteria.type === 'shared-segment'
        ? 'segment'
        : criteria.type || null
    },

    onCriteriaTypeChange(criteria, selected) {
      if (selected === 'message') {
        Object.assign(criteria, this.getDefaultMessage())
      } else if (selected === 'segment') {
        Object.assign(criteria, this.getDefaultSegment())
      } else if (selected === 'event') {
        Object.assign(criteria, this.getDefaultEvent())
      } else if (selected === 'date') {
        Object.assign(criteria, this.getDefaultDate())
      } else {
        Object.assign(criteria, this.getDefaultProfile())
      }
    },

    getDefaultProfile() {
      return {
        type: 'profile',
        field: '',
        condition: 'TextEquals',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultSegment() {
      return {
        type: 'segment',
        field: '',
        condition: '',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultDate() {
      return {
        type: 'date',
        field: 'first',
        condition: '',
        negator: false,
        conditionArgs: {}
      }
    },

    getDefaultEvent() {
      return {
        type: 'event',
        field: '',
        condition: 'TextEquals',
        negator: false,
        conditionArgs: { value: '' }
      }
    },

    getDefaultMessage() {
      return {
        type: 'message',
        field: 'first',
        condition: 'opened',
        negator: false,
        conditionArgs: {}
      }
    },

    addGroup() {
      this.validator.push([this.getDefaultProfile()])
    },

    addCriteria(group) {
      group.push(this.getDefaultProfile())
    },

    removeGroup(group) {
      let index = this.validator.indexOf(group)
      if (index >= 0) {
        this.validator.splice(index, 1)
      }
    },

    removeCriteria(criteria, fromGroup) {
      let index = fromGroup.indexOf(criteria)
      if (index >= 0) {
        fromGroup.splice(index, 1)
      }
    },

    removeDecisionGroup() {
      if (this.isLoading) {
        return
      }

      this.eventBus.$emit(
        'multidecision-successor-change-disable-all',
        this.action.id
      )

      // get validators
      const validators = { ...this.$parent.$parent.$parent.$refs }
      const successorMap = {}
      const successorTitles = { ...this.action.config.successorTitles }

      // remove unwanted tabs reference
      delete validators.tabs

      for (const [successorKey, value] of Object.entries(validators)) {
        // skip decision groups before the one being removed
        if (successorKey.charCodeAt(0) < this.successorKey.charCodeAt(0)) {
          successorMap[successorKey] = this.action.successors[successorKey]
          continue
        }

        let nextKey = String.fromCharCode(successorKey.charCodeAt(0) + 1)

        if (validators.hasOwnProperty(nextKey) && validators[nextKey]) {
          value.validator = validators[nextKey].validator
          value.validatorType = validators[nextKey].validatorType
          value.title = this.action.config.successorTitles[nextKey]
          successorMap[successorKey] = this.action.successors[nextKey]
          successorTitles[successorKey] = value.title
          value.key = moment().toDate().getTime()
        } else if (value) {
          value.validator = []
          value.validatorType = 'allOf'
          value.title = 'Untitled'
        }
      }

      this.key = moment().toDate().getTime()

      const keys = Object.keys(successorTitles)
      delete successorTitles[keys[keys.length - 1]]

      successorMap.else = this.action.successors.else

      this.workflowApi.updateAction(this.action.id, {
        successors: successorMap,
        config: { successorTitles: successorTitles }
      })
    },

    confirmDelete() {
      if (Object.keys(this.action.successors).length <= 2) {
        return
      }
      alert.show({
        confirm: true,
        type: 'warning',
        okText: 'Continue',
        title: 'Remove decision group',
        message: `This will remove the decision group (${this.successorKey.toUpperCase()}). Do you wish to continue?`,
        onOk: (resolve) => {
          this.removeDecisionGroup()
          resolve()
        }
      })
    },

    moveUp() {
      if (this.successorKey === 'a') {
        return
      }

      if (this.isLoading) {
        return
      }

      this.eventBus.$emit(
        'multidecision-successor-change-disable-all',
        this.action.id
      )

      const validators = { ...this.$parent.$parent.$parent.$refs }
      const tabs = validators.tabs

      // remove unwanted tabs reference
      delete validators.tabs

      const successorMap = {},
        successorTitles = { ...this.action.config.successorTitles },
        currentIndex = Object.keys(this.action.successors).indexOf(
          this.successorKey
        ),
        previousKey = Object.keys(this.action.successors)[currentIndex - 1],
        dataSet = {}

      dataSet[previousKey] = {
        validator: validators[previousKey].validator,
        validatorType: validators[previousKey].validatorType,
        successor: this.action.successors[previousKey],
        title: this.action.config.successorTitles[previousKey]
      }
      dataSet[this.successorKey] = {
        validator: validators[this.successorKey].validator,
        validatorType: validators[this.successorKey].validatorType,
        successor: this.action.successors[this.successorKey],
        title: this.action.config.successorTitles[this.successorKey]
      }

      for (const [successorKey, value] of Object.entries(validators)) {
        if (value === undefined) {
          continue
        }

        if (
          successorKey.charCodeAt(0) < previousKey.charCodeAt(0) ||
          successorKey.charCodeAt(0) > this.successorKey.charCodeAt(0)
        ) {
          successorMap[successorKey] = this.action.successors[successorKey]
          continue
        }

        if (successorKey === this.successorKey) {
          value.validator = dataSet[previousKey].validator
          value.validatorType = dataSet[previousKey].validatorType
          successorMap[successorKey] = dataSet[previousKey].successor
          successorTitles[successorKey] = dataSet[previousKey].title
          value.title = dataSet[previousKey].title
          value.key = moment().toDate().getTime()
        }

        if (successorKey === previousKey) {
          value.validator = dataSet[this.successorKey].validator
          value.validatorType = dataSet[this.successorKey].validatorType
          successorMap[successorKey] = dataSet[this.successorKey].successor
          successorTitles[successorKey] = dataSet[this.successorKey].title
          value.title = dataSet[this.successorKey].title
          value.key = moment().toDate().getTime()
        }
      }

      this.key = moment().toDate().getTime()
      successorMap.else = this.action.successors.else

      this.workflowApi.updateAction(this.action.id, {
        successors: successorMap,
        config: { successorTitles: successorTitles }
      })
      tabs.activeTab = 'decision-' + previousKey

      this.eventBus.$emit('multidecision-endpoint-update', this.action.id)
    },

    moveDown() {
      if (this.isLoading) {
        return
      }

      this.eventBus.$emit(
        'multidecision-successor-change-disable-all',
        this.action.id
      )

      const validators = { ...this.$parent.$parent.$parent.$refs }
      const tabs = validators.tabs

      // remove unwanted tabs reference
      delete validators.tabs

      const successorMap = {},
        successorTitles = { ...this.action.config.successorTitles },
        currentIndex = Object.keys(this.action.successors).indexOf(
          this.successorKey
        ),
        nextKey = Object.keys(this.action.successors)[currentIndex + 1],
        dataSet = {}

      if (!nextKey || nextKey === 'else') {
        return
      }

      dataSet[this.successorKey] = {
        validator: validators[this.successorKey].validator,
        validatorType: validators[this.successorKey].validatorType,
        successor: this.action.successors[this.successorKey],
        title: this.action.config.successorTitles[this.successorKey]
      }

      dataSet[nextKey] = {
        validator: validators[nextKey].validator,
        validatorType: validators[nextKey].validatorType,
        successor: this.action.successors[nextKey],
        title: this.action.config.successorTitles[nextKey]
      }

      for (const [successorKey, value] of Object.entries(validators)) {
        if (value === undefined) {
          continue
        }

        if (
          successorKey.charCodeAt(0) > nextKey.charCodeAt(0) ||
          successorKey.charCodeAt(0) < this.successorKey.charCodeAt(0)
        ) {
          successorMap[successorKey] = this.action.successors[successorKey]
          continue
        }

        if (successorKey === this.successorKey) {
          value.validator = dataSet[nextKey].validator
          value.validatorType = dataSet[nextKey].validatorType
          successorMap[successorKey] = dataSet[nextKey].successor
          successorTitles[successorKey] = dataSet[nextKey].title
          value.title = dataSet[nextKey].title
          value.key = moment().toDate().getTime()
        }

        if (successorKey === nextKey) {
          value.validator = dataSet[this.successorKey].validator
          value.validatorType = dataSet[this.successorKey].validatorType
          successorMap[successorKey] = dataSet[this.successorKey].successor
          successorTitles[successorKey] = dataSet[this.successorKey].title
          value.title = dataSet[this.successorKey].title
          value.key = moment().toDate().getTime()
        }
      }

      this.key = moment().toDate().getTime()
      successorMap.else = this.action.successors.else
      this.workflowApi.updateAction(this.action.id, {
        successors: successorMap,
        config: { successorTitles: successorTitles }
      })
      tabs.activeTab = 'decision-' + nextKey

      this.eventBus.$emit('multidecision-endpoint-update', this.action.id)
    }
  }
}
</script>
<style lang="sass" scoped>
.toolbox-large-decision
    padding: 20px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow: auto
.title
    margin-bottom: 20px
.group
    margin: 20px 0
    border-radius: 5px
    border: 1px solid $panel-default-border
    position: relative
    background-color: $gray-lighter

.criteria
    font-size: 12px
    position: relative
    display: flex
    flex-wrap: wrap
    border-bottom: 1px solid $panel-default-border

    &:last-child
        border-bottom: none
    &-type
        width: 145px

    &-action
        display: flex
        justify-content: flex-end

        &-button
            font-size: 18px
            margin-top: -3px
            display: flex
            align-items: center
            padding: 0 10px
            cursor: pointer

.logic
    color: $gray
    text-transform: uppercase
    font-weight: bold

    &-criteria
        position: absolute
        top: 56px
        left: 0
        background-color: $gray-lighter
        width: 65px
        text-align: center
.button-group
    display: flex
    justify-content: end
    margin-bottom: 25px

.spacing
    margin-left: 10px
</style>
