<template>
  <div>
    <span>Segment</span>
    <span v-if="isFieldValid">
      <span class="criteria-field-name">'{{ segName }}'</span>
    </span>
    <span v-else class="invalid-text">missing field</span>
    <span>{{ negatorText }}</span>
  </div>
</template>
<script>
import MissingCondition from './MissingCondition'

export default {
  props: ['field', 'negator', 'condition', 'conditionArgs'],
  data() {
    return {
      segName: ''
    }
  },

  computed: {
    isFieldValid() {
      return this.field !== ''
    },

    negatorText: function () {
      return !this.negator ? 'includes user' : 'excludes user'
    },

    conditionCmp() {
      try {
        return require(`./Profile/${this.condition}`).default
      } catch (e) {}

      return MissingCondition
    }
  },

  watch: {
    field: function () {
      this.segName = this.getSegmentName()
    }
  },

  created() {
    this.$store.dispatch('fetchSegments').then(() => {
      this.segName = this.getSegmentName()
    })
  },

  methods: {
    getSegmentName: function () {
      let segments = this.$store.state.project.segments
      if (segments === undefined) {
        return ''
      }

      let segment = segments.find(
        (segment) => segment.id.toString() === this.field
      )
      if (segment === undefined) {
        return ''
      }
      return segment.title
    }
  }
}
</script>
