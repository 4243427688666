<template>
  <div class="criteria-options">
    <select2 v-model="value.field" :options="fieldOptions" class="field" />
    <select2 v-model="negatorValue" class="negator" @input="onNegatorChange">
      <option value="is">IS</option>
      <option value="isnot">IS NOT</option>
    </select2>
    <select2
      v-model="value.condition"
      class="condition"
      :options="optionData"
    ></select2>

    <component
      :is="conditionCmp"
      v-model="value.conditionArgs"
      class="condition-args"
    ></component>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'

export default {
  components: { Select2 },

  inject: ['workflowApi'],
  props: ['value', 'features'],

  computed: {
    conditionCmp() {
      try {
        return require(`./Message/${this.value.condition}`).default
      } catch (e) {}

      return null
    },

    optionData() {
      let action = this.workflowApi.getAction(this.value.field)
      if (!action) {
        return []
      }

      let options = []
      switch (action.type) {
        case 'Email':
          options.push({
            text: 'Email',
            children: [
              ...(this.features.email_open_tracking
                ? [{ id: 'Opened', text: 'Opened' }]
                : []),
              ...(this.features.email_click_tracking
                ? [{ id: 'Clicked', text: 'Clicked' }]
                : [])
            ]
          })

          if (this.features.email_click_tracking) {
            options.push({
              text: 'Clicked Link',
              children: [
                {
                  id: 'ClickedLinkEqualTo',
                  text: 'Equal to',
                  title: 'Clicked Link Equal to'
                },
                {
                  id: 'ClickedLinkContains',
                  text: 'Containing',
                  title: 'Clicked Link Containing'
                }
              ]
            })
          }
          break
        case 'Push':
          options.push({
            text: 'Push',
            children: [
              { id: 'Delivered', text: 'Delivered' },
              { id: 'Opened', text: 'Opened' }
            ]
          })
          break
        case 'Inbox':
          options.push({
            text: 'Inbox',
            children: [
              { id: 'Delivered', text: 'Delivered' },
              { id: 'Opened', text: 'Opened' }
            ]
          })
          break
        case 'Sms':
          options.push({
            text: 'Sms',
            children: [{ id: 'Delivered', text: 'Delivered' }]
          })
      }
      return options
    },

    fieldOptions() {
      let messageOptions = {
        Email: { text: 'Email', children: [] },
        Push: { text: 'Push', children: [] },
        Inbox: { text: 'Inbox', children: [] },
        Sms: { text: 'Sms', children: [] }
      }

      this.workflowApi.getActions().forEach((action) => {
        switch (action.type) {
          case 'Email':
            if (
              this.features.email_open_tracking ||
              this.features.email_click_tracking
            ) {
              messageOptions[action.type].children.push({
                text: action.name,
                id: action.id
              })
            }
            break
          case 'Sms':
          case 'Push':
          case 'Inbox':
            messageOptions[action.type].children.push({
              text: action.name,
              id: action.id
            })
            break
        }
      })
      return Object.values(messageOptions).filter(
        (group) => group.children.length > 0
      )
    },

    negatorValue() {
      return this.value.negator ? 'isnot' : 'is'
    }
  },

  methods: {
    onNegatorChange(newValue) {
      this.value.negator = newValue !== 'is'
    }
  }
}
</script>
