<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Delivery Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">Arrived:</div>
        <div class="stats-bar-item-number">{{ arrivedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email" />
        <div class="stats-bar-item-title">Sent:</div>
        <div class="stats-bar-item-number">
          {{ sentTotal | number }}
          ({{ sentTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="inbox" />
        <div class="stats-bar-item-title">Delivered:</div>
        <div class="stats-bar-item-number">
          {{ deliveredTotal | number }}
          ({{ deliveredTotal | percentageOf(sentTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="arrow-redirect" />
        <div class="stats-bar-item-title">Bounced:</div>
        <div class="stats-bar-item-number">
          {{ bouncedTotal | number }}
          ({{ bouncedTotal | percentageOf(sentTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      title="How Many Profiles Have Arrived"
      title-class="action-color-email"
      y-label="Number of Profiles"
      style="margin-bottom: 30px"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import LineChart from '@/components/DateLineChart'

export default {
  components: {
    LineChart
  },

  mixins: [TrendLineMixin],
  props: [
    'arrivedTotal',
    'sentTotal',
    'deliveredTotal',
    'bouncedTotal',

    'dateRange',
    'arrivedStats',
    'sentStats'
  ],

  computed: {
    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Arrived',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Sent',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.sentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.sentStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
