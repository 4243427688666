<template>
  <div style="margin: 20px">
    <button
      v-if="!readOnly"
      type="button"
      class="btn btn-default btn-block"
      @click="eventBus.$emit('showLargeToolbox', true)"
    >
      Edit
    </button>

    <div class="panel panel-default">
      <div class="panel-body">
        <div class="decision-label">Current Criteria</div>
        <div v-show="!action.validator.length">No criteria</div>
        <div v-for="(group, index) in action.validator">
          <div class="decision-group">
            <div v-for="(criteria, index) in group" class="decision-criteria">
              <div
                :is="criteria.type"
                :field="criteria.field"
                :negator="criteria.negator"
                :condition="criteria.condition"
                :condition-args="criteria.conditionArgs"
              ></div>

              <div
                v-if="index < group.length - 1"
                class="decision-criteria_logic"
              >
                {{ criteriaLogicText }}
              </div>
            </div>
          </div>
          <div
            v-if="index < action.validator.length - 1"
            class="decision-group_logic"
          >
            {{ groupLogicText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Profile from './Decision/Profile'
import Message from './Decision/Message'
import Segment from './Decision/Segment'
import SharedSegment from './Decision/SharedSegment'
import Event from './Decision/Event'
import Date from './Decision/Date'

export default {
  components: {
    profile: Profile,
    message: Message,
    segment: Segment,
    sharedSegment: SharedSegment,
    event: Event,
    date: Date
  },

  inject: ['eventBus', 'workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    groupLogicText() {
      return this.action.validatorType === 'oneOf' ? 'or' : 'and'
    },

    criteriaLogicText() {
      return this.action.validatorType === 'oneOf' ? 'and' : 'or'
    },

    isOneOf() {
      return this.action.validatorType === 'oneOf'
    },

    isAllOf() {
      return this.action.validatorType === 'allOf'
    }
  }
}
</script>
<style lang="sass" scoped>
.decision-label
    font-weight: bold

.decision-group
    margin: 20px

.decision-group_logic
    font-weight: bold
    text-transform: uppercase

.decision-criteria_logic
    margin: 5px 0 5px 20px
    font-weight: bold
    text-transform: uppercase

.btn-block
    margin-bottom: 20px
</style>
