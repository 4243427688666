<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Sent'" glyph="email" />
      {{ stats['Email:sent'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Opens'" glyph="email-open" />
      {{ opens }}
    </li>
    <li>
      <icon v-tooltip:left="'Clicks'" glyph="mouse" />
      {{ clicks }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats', 'trackEmailOpen', 'trackEmailClick'],

  computed: {
    opens() {
      if (!this.trackEmailOpen) return 'N/A'

      return this.$options.filters.number(this.stats['Email:openedUnique'])
    },

    clicks() {
      if (!this.trackEmailClick) return 'N/A'

      return this.$options.filters.number(this.stats['Email:clickedUnique'])
    }
  }
}
</script>
