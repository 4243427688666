<template>
  <div class="input-group">
    <input
      ref="dateTimePicker"
      type="text"
      class="form-control2"
      placeholder="Select date"
      :disabled="disabled"
    />
    <span class="input-group-addon">
      <span class="glyphicon glyphicon-calendar"></span>
    </span>
  </div>
</template>
<script>
import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'
import moment from 'moment'

export default {
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean
    }
  },

  mounted() {
    this.dateTimeInput = $(this.$refs.dateTimePicker)
    this.dateTimeInput
      .datetimepicker({
        useCurrent: false,
        format: 'D MMMM YYYY h:mm A'
      })
      .on('dp.change', this.onValueChange)
    this.dateTimePicker = this.dateTimeInput.data('DateTimePicker')
    this.dateTimePicker.minDate(moment())
    this.dateTimePicker.date(new Date(this.value))
  },

  beforeDestroy() {
    this.dateTimePicker.destroy()
    this.dateTimeInput.off()
  },

  methods: {
    onValueChange() {
      const dateVal = this.dateTimePicker
        .date()
        .seconds(0)
        .milliseconds(0)
        .toJSON()
      this.$emit('input', dateVal)
    }
  }
}
</script>
<style scoped>
.input-group {
  margin-top: 15px;
  width: 50%;
}
.form-control2 {
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  display: block;
  font-size: 14px;
  line-height: 1.428571429;
  color: #707070;
}
</style>
