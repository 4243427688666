<template>
  <span>
    text ending with
    <span v-if="conditionArgs.value" class="criteria-condition-args">
      '{{ conditionArgs.value }}'
    </span>
    <span v-else class="invalid-text">missing text</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs']
}
</script>
