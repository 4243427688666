<template>
  <div :class="rootClass">
    <label>Ignore Subscription:</label>
    <toggle-button
      :disabled="readOnly"
      color="#1d87c8"
      :value="ignoreSubscription"
      :sync="true"
      @change="ignoreSubscriptionClick($event.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'IgnoreSubscription',
  props: {
    value: {
      required: true,
      default: 'doNotIgnore'
    },
    readOnly: {
      required: true
    },
    rootClass: {
      required: false,
      default: 'col-md-12'
    }
  },
  data() {
    return {
      ignoreSubscription: false
    }
  },

  mounted() {
    if (this.value === 'default') {
      this.$emit('input', 'doNotIgnore')
    }

    this.ignoreSubscription = this.value === 'ignore'
  },
  methods: {
    ignoreSubscriptionClick(value) {
      if (this.readOnly) {
        return
      }

      if (value) {
        this.ignoreSubscription = true
        this.$emit('input', 'ignore')
      } else {
        this.ignoreSubscription = false
        this.$emit('input', 'doNotIgnore')
      }
    }
  }
}
</script>

<style scoped></style>
