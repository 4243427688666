<template>
  <multiselect
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    placeholder="Select Option"
    track-by="id"
    label="label"
    select-label=""
    selected-label=""
    deselect-label=""
    :option-height="38"
    :searchable="false"
    :allow-empty="false"
    @input="updateValue"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    disabled: {
      type: Boolean
    }
  },

  data() {
    return {
      options: [
        { id: 1, label: 'Retry for' },
        { id: 2, label: 'Retry until' },
        { id: 3, label: 'Do not retry' }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.value)
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value.id)
    }
  }
}
</script>
