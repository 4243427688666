<template>
  <multiselect
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    placeholder="Select Option"
    @input="updateValue"
    track-by="id"
    label="label"
    select-label=""
    selected-label=""
    deselect-label=""
    :optionHeight="36"
    :searchable="false"
    :allow-empty="false"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [
        { id: 0, label: 'None' },
        { id: 1, label: 'Text' }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.value)
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value.id)
    }
  }
}
</script>
