<template>
  <div style="margin: 20px">
    <div class="form-group">
      <label class="control-label">Audience:</label>
      <multiselect
        :value="selectedAudience"
        :options="externalAudience"
        label="name"
        :disabled="readOnly"
        placeholder="Select Audience"
        track-by="id"
        :custom-label="audienceLabel"
        @input="onSelect"
      ></multiselect>
    </div>

    <div class="form-group">
      <label class="control-label">Option:</label>
      <multiselect
        :value="selectedOption"
        :options="options"
        label="label"
        :disabled="readOnly"
        placholder="Select Option"
        @input="onInput"
      ></multiselect>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  data() {
    return {
      options: [
        { id: 0, label: 'Add to Audience' },
        { id: 1, label: 'Remove from Audience' }
      ]
    }
  },

  computed: {
    externalAudience() {
      if (
        !this.$store.getters.isFeatureEnabled('meta') &&
        !this.$store.getters.isFeatureEnabled('googleads')
      ) {
        return []
      }

      if (
        this.$store.getters.isFeatureEnabled('meta') &&
        !this.$store.getters.isFeatureEnabled('googleads')
      ) {
        return this.$store.state.project.externalAudience.filter(
          (audience) => audience.audience_type === 1
        )
      }

      if (
        !this.$store.getters.isFeatureEnabled('meta') &&
        this.$store.getters.isFeatureEnabled('googleads')
      ) {
        return this.$store.state.project.externalAudience.filter(
          (audience) => audience.audience_type === 2
        )
      }

      return this.$store.state.project.externalAudience
    },

    selectedAudience() {
      return this.$store.state.project.externalAudience.find(
        (audience) => audience.id === this.action.external_audience_id
      )
    },

    selectedOption() {
      return this.options.find((option) => option.id === this.action.event)
    }
  },

  created() {
    this.$store.dispatch('fetchExternalAudience')
  },

  methods: {
    onSelect(option) {
      if (!option) {
        return
      }
      this.workflowApi.updateAction(this.action.id, {
        external_audience_id: option.id
      })
    },

    onInput(option) {
      if (!option) {
        return
      }
      this.workflowApi.updateAction(this.action.id, { event: option.id })
    },

    audienceLabel(option) {
      switch (option.audience_type) {
        case 1:
          return 'Meta Ads - ' + option.name

        case 2:
          return 'Google Ads - ' + option.name
      }
    }
  }
}
</script>

<style scoped></style>
