<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Engagement Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-open" />
        <div class="stats-bar-item-title">Opens:</div>
        <div class="stats-bar-item-number">
          {{ uniqueOpensTotal | number }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ uniqueOpensTotal | percentageOf(deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">Clicks:</div>
        <div class="stats-bar-item-number">
          {{ uniqueClicksTotal | number }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ uniqueClicksTotal | percentageOf(deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-unsubscribe" />
        <div class="stats-bar-item-title">Unsubscribes:</div>
        <div class="stats-bar-item-number">
          {{ unsubscribesTotal | number }}
          ({{ unsubscribesTotal | percentageOf(deliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />

    <div class="btn-group pull-right">
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'numbers' }"
        @click="viewMode = 'numbers'"
      >
        Numbers
      </button>
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'percent' }"
        @click="viewMode = 'percent'"
      >
        Percent
      </button>
    </div>

    <keep-alive>
      <date-line-chart
        v-if="viewMode === 'numbers'"
        key="numbersChart"
        :chart-data="numbersChartData"
        title="Opens, Clicks and Unsubscribes"
        title-class="action-color-email"
        y-label="Number of Profiles"
      />

      <date-line-chart
        v-if="viewMode === 'percent'"
        key="percentChart"
        :y-axes-percent="true"
        :chart-data="percentChartData"
        title="Opens, Clicks and Unsubscribes"
        title-class="action-color-email"
        y-label="% Percentage"
      />
    </keep-alive>
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  components: {
    DateLineChart
  },

  mixins: [TrendLineMixin],
  props: [
    'deliveredTotal',
    'uniqueOpensTotal',
    'uniqueClicksTotal',
    'unsubscribesTotal',

    'dateRange',
    'sentStats',
    'openStats',
    'clickStats'
  ],

  data() {
    return {
      viewMode: 'numbers'
    }
  },

  computed: {
    openedPercentStats() {
      const total = [],
        sentStats = this.sentStats,
        openStats = this.openStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((openStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    clickedPercentStats() {
      const total = [],
        sentStats = this.sentStats,
        clickStats = this.clickStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((clickStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    numbersChartData() {
      const openStats = this.openStats === 'N/A' ? [] : this.openStats
      const clickStats = this.clickStats === 'N/A' ? [] : this.clickStats

      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Opens',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: openStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(openStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: clickStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(clickStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Opens',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.openedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.openedPercentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.clickedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.clickedPercentStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
