<template>
  <div style="padding: 20px">
    <label>Email Engagement Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-open" />
        <div class="stats-bar-item-title">Opens:</div>
        <div v-if="isEmailOpenTrackingEnabled" class="stats-bar-item-number">
          {{ emailUniqueOpensTotal | number }}
          ({{ emailUniqueOpensTotal | percentageOf(emailDeliveredTotal) }})
        </div>
        <div v-else class="stats-bar-item-number">N/A</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">Clicks:</div>
        <div v-if="isEmailClickTrackingEnabled" class="stats-bar-item-number">
          {{ emailUniqueClicksTotal | number }}
          ({{ emailUniqueClicksTotal | percentageOf(emailDeliveredTotal) }})
        </div>
        <div v-else class="stats-bar-item-number">N/A</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-unsubscribe" />
        <div class="stats-bar-item-title">Unsubscribes:</div>
        <div class="stats-bar-item-number">
          {{ emailUnsubscribesTotal | number }}
          ({{ emailUnsubscribesTotal | percentageOf(emailDeliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />

    <div class="btn-group pull-right">
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'numbers' }"
        @click="viewMode = 'numbers'"
      >
        Numbers
      </button>
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'percent' }"
        @click="viewMode = 'percent'"
      >
        Percent
      </button>
    </div>

    <keep-alive>
      <date-line-chart
        v-if="viewMode === 'numbers'"
        key="numbersChart"
        :chart-data="numbersChartData"
        title="Opens, Clicks and Unsubscribes"
        title-class="action-color-email"
        yLabel="Number of Profiles"
      />

      <date-line-chart
        v-if="viewMode === 'percent'"
        key="percentChart"
        :yAxesPercent="true"
        :chart-data="percentChartData"
        title="Opens, Clicks and Unsubscribes"
        title-class="action-color-email"
        yLabel="% Percentage"
      />
    </keep-alive>
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  props: [
    'emailDeliveredTotal',
    'emailUniqueOpensTotal',
    'emailUniqueClicksTotal',
    'emailUnsubscribesTotal',

    'dateRange',
    'emailSentStats',
    'emailUniqueOpensStats',
    'emailUniqueClicksStats',

    'workflow'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  data() {
    return {
      viewMode: 'numbers'
    }
  },

  computed: {
    isEmailOpenTrackingEnabled() {
      return this.workflow.features.email_open_tracking !== undefined
        ? this.workflow.features.email_open_tracking
        : true
    },

    isEmailClickTrackingEnabled() {
      return this.workflow.features.email_click_tracking !== undefined
        ? this.workflow.features.email_click_tracking
        : true
    },

    openedPercentStats() {
      const total = [],
        sentStats = this.emailSentStats,
        openStats = this.emailUniqueOpensStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((openStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    clickedPercentStats() {
      const total = [],
        sentStats = this.emailSentStats,
        clickStats = this.emailUniqueClicksStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((clickStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    numbersChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Opens',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.isEmailOpenTrackingEnabled
              ? this.emailUniqueOpensStats
              : []
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.isEmailOpenTrackingEnabled
              ? this.getTrendLine(this.emailUniqueOpensStats)
              : [],
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.isEmailClickTrackingEnabled
              ? this.emailUniqueClicksStats
              : []
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.isEmailClickTrackingEnabled
              ? this.getTrendLine(this.emailUniqueClicksStats)
              : [],
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Opens',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.isEmailOpenTrackingEnabled ? this.openedPercentStats : []
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.isEmailOpenTrackingEnabled
              ? this.getTrendLine(this.openedPercentStats)
              : [],
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.isEmailClickTrackingEnabled
              ? this.clickedPercentStats
              : []
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.isEmailClickTrackingEnabled
              ? this.getTrendLine(this.clickedPercentStats)
              : [],
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
