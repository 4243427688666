import './styles/main.sass'
import '@/providers/ChartDefaults'

import moment from 'moment'
moment.locale('en-GB')

import Vue from 'vue'

// Vue.config.devtools = true;
// Vue.config.performance = true;

import App from './views/App'

import store from '@/stores/store'
import router from '@/providers/Router'

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import VueTruncate from 'vue-truncate-filter'
Vue.use(VueTruncate)

import VueDialog from '@/libs/VueDialog'
VueDialog.register({ router, store })

import ActionIcon from '@/components/ActionIcon'
Vue.component('action-icon', ActionIcon)

import Tooltip from '@/directives/Tooltip'
Vue.directive('tooltip', Tooltip)

import ButtonLoading from '@/directives/ButtonLoading'
Vue.directive('btn-loading', ButtonLoading)

import Icon from '@/components/Icon'
Vue.component('icon', Icon)

import Gravatar from '@/components/Gravatar'
Vue.component('gravatar', Gravatar)

import Editable from '@/components/Editable'
Vue.component('editable', Editable)

import Select2 from '@/components/Select2'
Vue.component('select2', Select2)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import 'xp-fork-colorpicker/colorpicker.js'
import 'xp-fork-redactor2/redactor.js'
import 'xp-fork-redactor2/custom-plugins/fontsize.js'
import 'xp-fork-redactor2/custom-plugins/fontfamily.js'
import 'xp-fork-redactor2/custom-plugins/fontcolor.js'
import 'xp-fork-redactor2/custom-plugins/cleanformat.js'
import 'xp-fork-redactor2/custom-plugins/alignment.js'
import 'xp-fork-redactor2/custom-plugins/emojis.js'
import 'xp-fork-redactor2/custom-plugins/personalization.js'
import 'xp-fork-redactor2/custom-plugins/snippet.js'

import BeeShopboxPlugin from '@/components/AddOns/ProductRecommendations/BeeShopboxPlugin'
import BeeVoucherPlugin from '@/components/AddOns/Vouchers/BeeVoucherPlugin.vue'
Vue.component('BeeShopboxPlugin', BeeShopboxPlugin)
Vue.component('BeeVoucherPlugin', BeeVoucherPlugin)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Vuedals from 'vuedals'
import { Component as Vuedal } from 'vuedals'
Vue.use(Vuedals)
Vue.component('vuedal', Vuedal)

import * as Toastr from 'toastr'
Toastr.options.positionClass = 'toast-top-center'

import VueAcl from '@/libs/VueAcl'
Vue.use(VueAcl)

import ElementUi from 'element-ui'
import ElementLocale from 'element-ui/lib/locale/lang/en'
import '@/styles/element.sass'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUi, { locale: ElementLocale })

Vue.filter('number', (v) => {
  if (v === 'N/A') return 'N/A'

  return (Number(v) || 0).toLocaleString('en')
})
Vue.filter('percentageOf', (num, total, decimalPlaces = 0) => {
  return Number(num / total || 0).toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: decimalPlaces
  })
})
Vue.filter('date', (val, format) => moment(val).format(format))
Vue.filter('fromNow', (value) => moment.utc(value).fromNow())

new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store
})

window.addEventListener('dragover', (e) => e.preventDefault())
window.addEventListener('drop', (e) => e.preventDefault())
