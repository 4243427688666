<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Arrived'" glyph="user" />
      {{ stats['Promotion:trigger'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
