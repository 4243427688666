<template>
  <div style="margin: 20px">
    <button type="button" class="btn btn-default btn-block" @click="openEditor">
      {{ !isAvailable ? 'Preview' : 'Edit' }}
    </button>

    <div class="panel panel-default">
      <div class="panel-body">
        <div class="inbox-prop-name">Inbox Type:</div>
        <div class="inbox-prop-value">{{ formattedInboxType }}</div>

        <div class="inbox-prop-name">Default Language:</div>
        <div class="inbox-prop-value">{{ config.defaultLang | langName }}</div>

        <div v-if="isExclusionEnabled">
          <div class="inbox-prop-name">Exclusion category:</div>
          <div
            v-if="
              !action.config.exclusion || action.config.exclusion.check === false
            "
            class="inbox-prop-value"
          >
            Not Enabled
          </div>
          <div
            v-else-if="
              (action.config.exclusion && action.config.exclusion.check) ||
              displayExclusionError
            "
            class="inbox-prop-value"
            :class="{
              'invalid-text':
                displayExclusionError ||
                $v.action.config.exclusion.category.$invalid
            }"
          >
            {{ exclusionCategoryName }}
            <template
              v-if="
                displayExclusionError ||
                !$v.action.config.exclusion.category.required
              "
            >
              missing exclusion category
            </template>
          </div>
        </div>

        <template v-if="config.languages.length > 1">
          <div class="inbox-prop-name">Additional Languages:</div>
          <div class="inbox-prop-value">{{ formattedLanguage }}</div>
        </template>

        <div class="inbox-prop-name">Default Action:</div>
        <div class="inbox-prop-value">{{ formattedDefaultAction }}</div>

        <template v-if="config.push_action.action === 'url'">
          <div class="inbox-prop-name">URL:</div>
          <div
            class="inbox-prop-value"
            :class="{
              'invalid-text': $v.action.config.push_action.url.$invalid
            }"
          >
            {{ config.push_action.url }}
            <template v-if="!$v.action.config.push_action.url.required">
              missing URL
            </template>
          </div>

          <div class="inbox-prop-name">Open in a new tab:</div>
          <div class="inbox-prop-value">
            {{ config.push_action.url_blank ? 'Yes' : 'No' }}
          </div>
        </template>

        <template v-if="config.push_action.action === 'deeplink'">
          <div class="inbox-prop-name">URL:</div>
          <div
            class="inbox-prop-value"
            :class="{
              'invalid-text': $v.action.config.push_action.deeplink.$invalid
            }"
          >
            {{ config.push_action.deeplink }}
            <template v-if="!$v.action.config.push_action.deeplink.required">
              missing Deeplink
            </template>
          </div>
        </template>

        <template v-if="config.payload_add.length > 0">
          <div class="inbox-prop-name">Payload:</div>
          <div v-for="payload in config.payload_add">
            <div class="inbox-prop-value">
              { {{ payload.key }}: {{ payload.value }} }
            </div>
          </div>
        </template>

        <div class="inbox-prop-name">Persist content in inbox:</div>
        <div class="inbox-prop-value" v-if="config.persist_content === 'until'">
          {{ config.persist_content_until_date }}
          <span v-if="config.persist_content_until_date_timezone === 'project'">
            {{ config.project_timezone }}
          </span>
          <span
            v-else-if="config.persist_content_until_date_timezone === 'profile'"
          >
            in the timezone of the profile.
          </span>
          <span v-else>
            {{ config.persist_content_until_date_timezone }}
          </span>
        </div>
        <div
          class="inbox-prop-value"
          v-else-if="config.persist_content === 'for'"
        >
          For {{ config.persist_content_for_n }}
          {{ config.persist_content_for_unit }}
          <span v-if="config.persist_content_for_n > 1">s</span>
        </div>
        <div class="inbox-prop-value" v-else>Do not remove</div>
      </div>
    </div>

    <div class="preview-container">
      <inbox-message-preview
        :message="formattedMessage"
        :message-type="config.inbox_type"
        :message-style="messageStyles"
        :global-styles="{}"
      />
    </div>
  </div>
</template>

<script>
import InboxMessagePreview from '@/views/WorkflowEdit/ToolboxesLarge/Inbox/InboxMessagePreview.vue'
import { requiredIf } from 'vuelidate/lib/validators'
import ISO6391 from 'iso-639-1'
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'

export default {
  name: 'Inbox',

  components: { InboxMessagePreview },

  props: ['action', 'readOnly'],

  inject: ['eventBus', 'workflowApi'],

  mixins: [NationalExclusionCategoriesMixin],

  filters: {
    langName(lang) {
      return ISO6391.getName(lang)
    }
  },

  validations: {
    action: {
      config: {
        push_action: {
          url: {
            required: requiredIf((e) => {
              return e.action === 'url'
            })
          },
          deeplink: {
            required: requiredIf((e) => {
              return e.action === 'deeplink'
            })
          }
        },
        exclusion: {
          category: {
            required: requiredIf((e) => {
              return e.check || false
            })
          }
        }
      }
    }
  },

  data() {
    return {}
  },

  computed: {
    isAvailable() {
      return !this.readOnly && this.$store.getters.inboxEnabled
    },

    config() {
      return this.action.config
    },

    formattedInboxType() {
      const inboxType = this.config.inbox_type

      if (inboxType === 'card') {
        return 'Card'
      }

      return 'Alert'
    },

    formattedLanguage() {
      return this.config.languages
        .filter((language) => language !== this.action.config.defaultLang)
        .map((language) => ISO6391.getName(language))
        .join(', ')
    },

    formattedDefaultAction() {
      const action = this.config.push_action.action

      if (action === 'url') {
        return 'URL'
      }

      if (action === 'deeplink') {
        return 'Deeplink'
      }

      return 'None'
    },

    formattedMessage() {
      return {
        title:
          this.config.messages[this.config.defaultLang].push_title ||
          'No Content',
        text: this.messageText,
        icon: this.config.push_icon || ''
      }
    },

    messageText() {
      const messageText =
        this.config.messages[this.config.defaultLang].push_text || 'No Content'

      if ((this.config.voucher_code_enabled || 0) === 0) return messageText

      if (this.config.voucher_code_position === 0) {
        return 'X6RgzCXb ' + messageText
      }

      return messageText + ' X6RgzCXb'
    },

    messageStyles() {
      if (this.config.inbox_type === 0) {
        return {}
      }

      return {
        bg: this.config.inbox_style.bg || '',
        title_bg: this.config.inbox_style.title_bg || ''
      }
    },

    exclusionCategoryName() {
      if (
        !(this.action.config.exclusion && this.action.config.exclusion.category)
      )
        return ''

      const value = this.getNecLabelByValue(this.action.config.exclusion.category)
      if (value === undefined) {
        this.action.config.exclusion.check = false
        this.action.config.exclusion.category = null
      }

      return value
    },

    displayExclusionError() {
      return this.isExclusionEnabled && !this.action.config.exclusion
    }
  },

  methods: {
    openEditor() {
      this.eventBus.$emit('showLargeToolbox', true)
    }
  }
}
</script>
<style lang="sass" scoped>
.btn-block
    margin-bottom: 20px

.inbox-prop-name
    font-weight: bold

.inbox-prop-value
    margin-bottom: 10px

.preview-container
    display: flex
    justify-content: center
    background: #083146
    border-radius: 4px
    padding: 10px
</style>
