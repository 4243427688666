<template>
  <div class="safeTimeSection">
    <div class="safeTimeLabel">
      <label>Allowed Resume Window:</label>
    </div>
    <div class="safeTimeWrapper">
      <div ref="startSafeTime" class="input-group date startSafeTime">
        <input
          type="text"
          class="form-control"
          :disabled="readOnly"
          placeholder="Start time..."
        />
        <span class="input-group-addon">
          <icon glyph="clock" />
        </span>
      </div>
    </div>
    <div class="safeTimeWrapper">
      <div ref="endSafeTime" class="input-group date endSafeTime">
        <input
          type="text"
          class="form-control"
          :disabled="readOnly"
          placeholder="End time..."
        />
        <span class="input-group-addon">
          <icon glyph="clock" />
        </span>
      </div>
    </div>
    <span class="help-block">
      Delays which end outside of these hours will hold the contact until the
      next allowed resume time.
    </span>
  </div>
</template>

<script>
import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'

export default {
  props: ['action', 'readOnly'],

  watch: {
    'action.options.startSafeTime': 'updateTimePickers',
    'action.options.endSafeTime': 'updateTimePickers'
  },

  mounted() {
    $(this.$refs.startSafeTime)
      .add(this.$refs.endSafeTime)
      .datetimepicker({
        format: 'h:mm A',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.startSafeTime = $(this.$refs.startSafeTime).data('DateTimePicker')
    this.endSafeTime = $(this.$refs.endSafeTime).data('DateTimePicker')

    this.updateTimePickers()
  },

  methods: {
    updateTimePickers() {
      this.preventSave = true

      if (this.action.options.startSafeTime) {
        this.startSafeTime.date(this.action.options.startSafeTime)
      }

      if (this.action.options.endSafeTime) {
        this.endSafeTime.date(this.action.options.endSafeTime)
      }

      this.preventSave = false
    },

    saveOptions() {
      if (this.preventSave) return

      const startTime = this.startSafeTime.date()
      const endTime = this.endSafeTime.date()

      this.$emit(
        'saveSafeTime',
        startTime ? startTime.format('h:mm A') : '',
        endTime ? endTime.format('h:mm A') : ''
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.safeTimeSection
    margin-top: 15px
    > .safeTimeWrapper
        width: 49%
        display: inline-block
        &:last-child
            float: right
        input.form-control
            z-index: auto
</style>
