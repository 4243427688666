<template>
  <div>
    <label>For:</label>
    <time-period
      :value="action.options.interval"
      :disabled="readOnly"
      @input="updateInterval"
    />
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import TimePeriod from '@/components/SimpleTimePeriod'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'

export default {
  components: {
    AllowedResumeWindowSection,
    TimePeriod
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateInterval(interval) {
      this.saveChanges({
        type: 'timePeriod',
        interval
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    }
  }
}
</script>
