<template>
  <span>
    a number greater than
    <span v-if="isNumeric(conditionArgs.value)" class="criteria-condition-args">
      '{{ conditionArgs.value }}'
    </span>
    <span v-else class="invalid-text">missing number</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  methods: {
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    }
  }
}
</script>
