<template>
  <div style="margin: 20px">
    <button type="button" class="btn btn-default btn-block" @click="openEditor">
      {{ readOnly ? 'Preview' : 'Edit' }}
    </button>

    <div class="panel panel-default">
      <div class="panel-body">
        <div class="email-prop-name">Subject Line:</div>
        <div
          class="email-prop-value"
          :class="{ 'invalid-text': $v.action.subject.$invalid }"
        >
          {{ action.subject }}
          <template v-if="!$v.action.subject.required">
            missing subject
          </template>
          <template
            v-if="
              $v.action.subject.required && !$v.action.subject.maxLengthValue
            "
          >
            (too long)
          </template>
        </div>

        <div class="email-prop-name">From Address:</div>
        <div
          class="email-prop-value"
          :class="{ 'invalid-text': $v.action.fromAddress.$invalid }"
        >
          {{ action.fromAddress }}
          <template v-if="!$v.action.fromAddress.required">
            missing from address
          </template>
        </div>

        <div class="email-prop-name">From Name:</div>
        <div
          class="email-prop-value"
          :class="{ 'invalid-text': $v.action.fromName.$invalid }"
        >
          {{ action.fromName }}
          <template v-if="!$v.action.fromName.required">
            missing from name
          </template>
          <template
            v-if="
              $v.action.fromName.required && !$v.action.fromName.maxLengthValue
            "
          >
            (too long)
          </template>
        </div>

        <template v-if="action.replyToAddress || action.replyToName">
          <div class="email-prop-name">Reply-To Email:</div>
          <div
            class="email-prop-value"
            :class="{ 'invalid-text': $v.action.replyToAddress.$invalid }"
          >
            <template>{{ action.replyToAddress }}</template>
            <template v-if="!$v.action.replyToAddress.required">
              missing reply-to address
            </template>
          </div>

          <div class="email-prop-name">Reply-To Name:</div>
          <div
            class="email-prop-value"
            :class="{ 'invalid-text': $v.action.replyToName.$invalid }"
          >
            <template>{{ action.replyToName }}</template>
            <template v-if="!$v.action.replyToName.required">
              missing reply-to name
            </template>
            <template
              v-if="
                $v.action.replyToName.required &&
                !$v.action.replyToName.maxLengthValue
              "
            >
              (too long)
            </template>
          </div>
        </template>
        <div v-if="isExclusionEnabled">
          <div class="email-prop-name">Exclusion category:</div>
          <div
            v-if="
              action.config.exclusion && action.config.exclusion.check === false
            "
            class="email-prop-value"
          >
            Not Enabled
          </div>
          <div
            v-if="
              (action.config.exclusion && action.config.exclusion.check) ||
              displayExclusionError
            "
            class="email-prop-value"
            :class="{
              'invalid-text':
                displayExclusionError ||
                $v.action.config.exclusion.category.$invalid
            }"
          >
            {{ exclusionCategoryName }}
            <template
              v-if="
                displayExclusionError ||
                !$v.action.config.exclusion.category.required
              "
            >
              missing exclusion category
            </template>
          </div>
        </div>
        <div class="email-prop-name">Message Type:</div>
        <div class="email-prop-value">
          {{ ignoreSubscriptionMap(action.config.ignore_subscription) }}
        </div>
      </div>
    </div>
    <div v-if="!action.contentId" class="panel panel-default">
      <div class="panel-body">No content</div>
    </div>
    <div v-else class="panel panel-default email-preview">
      <iframe
        class="email-preview-content"
        :srcdoc="contentModel.content"
      ></iframe>
    </div>
  </div>
</template>
<script>
import {
  required,
  requiredIf,
  email,
  maxLength
} from 'vuelidate/lib/validators'
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'

export default {
  inject: ['eventBus', 'contentApi'],
  props: ['action', 'readOnly'],
  mixins: [NationalExclusionCategoriesMixin],

  data() {
    return {
      contentModel: {
        contentId: false,
        content: '',
        editor: 'builder'
      }
    }
  },

  validations: {
    action: {
      subject: {
        required,
        maxLengthValue: maxLength(255)
      },
      fromName: {
        required,
        maxLengthValue: maxLength(255)
      },
      fromAddress: {
        required,
        email,
        maxLengthValue: maxLength(255)
      },
      replyToAddress: {
        email,
        required: requiredIf(
          (action) => action.replyToName || action.replyToAddress
        ),
        maxLengthValue: maxLength(255)
      },
      replyToName: {
        required: requiredIf(
          (action) => action.replyToName || action.replyToAddress
        ),
        maxLengthValue: maxLength(255)
      },
      config: {
        exclusion: {
          category: {
            required: requiredIf((e) => {
              return e.check || false
            })
          }
        }
      }
    }
  },

  computed: {
    exclusionCategoryName() {
      if (
        !(this.action.config.exclusion && this.action.config.exclusion.category)
      )
        return ''

      const value = this.getNecLabelByValue(this.action.config.exclusion.category)
      if (value === undefined) {
        this.action.config.exclusion.check = false
        this.action.config.exclusion.category = null
      }

      return value
    },
    displayExclusionError() {
      return this.isExclusionEnabled && !this.action.config.exclusion
    }
  },

  watch: {
    'action.contentId': {
      immediate: true,
      handler() {
        if (!this.action.contentId) return

        this.contentApi
          .load(this.action.contentId)
          .then(({ contentId, content, meta }) => {
            this.contentModel = {
              contentId,
              content,
              editor: meta.editor
            }
          })
          .catch(() => {
            this.contentModel = {
              contentId: false,
              content: '',
              editor: 'builder'
            }
          })
      }
    }
  },

  methods: {
    openEditor() {
      this.eventBus.$emit('showLargeToolbox', true)
    },

    ignoreSubscriptionMap(type) {
      const map = {
        default: 'Marketing',
        ignore: 'Transactional',
        doNotIgnore: 'Marketing'
      }

      return map[type] || 'Marketing'
    }
  }
}
</script>
<style lang="sass" scoped>
.email-prop-name
    font-weight: bold

.email-prop-value
    margin-bottom: 10px
    word-break: break-word

.email-preview
    position: relative
    background-color: #fff !important
    overflow: hidden
    height: 250px
    &:after
        content: ''
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

    .email-preview-content
        width: 700px
        height: 2000px
        transform: scale(0.66)
        transform-origin: 0 0
        border: none

.btn-block
    margin-bottom: 20px
</style>
