<template>
  <div>
    <h3>Message Templates</h3>
    <div class="form-group">
      <label for="templateSearch" class="control-label">
        Search Templates:
      </label>
      <input
        id="templateSearch"
        v-model="search"
        class="form-control"
        type="text"
        placeholder="Search"
        aria-label="Search"
      />
    </div>
    <h5>Select a Template:</h5>
    <div v-if="templateInfo.templateTitle" class="template-text">
      <span v-tooltip:top="templateTooltip">
        Created from template: {{ templateInfo.templateTitle }}
      </span>
    </div>
    <div class="row">
      <div v-for="template in filteredTemplates" class="col-md-2">
        <div
          v-tooltip:top="template.title"
          class="thumbnail"
          @click="importTemplate(template)"
        >
          <img v-if="template.previewImage" :src="template.previewImage" />
          <img
            v-else
            src="@/images/custom-template.svg"
            class="custom-template"
          />
          <div class="caption text-center">{{ template.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import XpApi from '@/libs/XpApi'
import alert from '@/libs/Alert'
import * as Toastr from 'toastr'
import { MessageTypes, PushTypes } from './MessageTypesEnum'
import PushActions from './PushActionsEnum'
import _ from 'lodash'

export default {
  name: 'TemplateSection',
  inject: ['workflowApi'],
  props: {
    messageType: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      MessageTypes,
      PushTypes,
      search: ''
    }
  },

  computed: {
    isEmptyMessage() {
      if (this.messageType === MessageTypes.SMS) {
        return Object.values(this.value.messages).every((msg) => {
          return Object.values(msg).every(
            (item) => item === null || item === ''
          )
        })
      }

      const pushType = this.PushTypes[this.messageType] || null
      if (pushType) {
        const fields = [
          'pictureUrl',
          'pictureVariant',
          'url',
          'iconUrl',
          'defaultAction.value'
        ]
        const isEmptyMessages = Object.values(
          this.value[pushType].messages
        ).every((msg) => {
          return Object.values(msg).every(
            (item) => item === null || item === ''
          )
        })
        const isEmptyFields = fields.every((field) => {
          const item = _.get(this.value[pushType], field, null)
          if (Array.isArray(item)) {
            return !item.length
          }
          return item === null || item === ''
        })

        return isEmptyMessages && isEmptyFields
      }

      return false
    },
    languages() {
      return this.value.languages
    },
    templates() {
      return this.$store.getters.getTemplates(this.messageType)
    },
    filteredTemplates() {
      return this.templates.filter((item) =>
        item.title.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    templateTooltip() {
      const lastApplied = moment(this.templateInfo.lastApplied).format(
        'DD/MMM/YYYY HH:mm'
      )
      return `Applied by ${this.templateInfo.whoApplied} on ${lastApplied}`
    },
    templateInfo() {
      return _.get(this.value, ['templateInfo', this.messageType], {})
    }
  },

  methods: {
    importTemplate(template) {
      const requests = []
      this.languages.forEach((lang) => {
        requests.push(
          XpApi.get(
            `projects/${this.$store.state.app.project.id}/campaign-templates/${template.id}`,
            { params: { lang, expand: 'message' } }
          ).then(({ data }) => data)
        )
      })
      Promise.all(requests).then((messages) => {
        this.applyTemplate(messages)
      })
    },
    applyTemplate(messages) {
      if (this.isEmptyMessage) {
        return this.doApply(messages)
      }
      alert.show({
        confirm: true,
        type: 'warning',
        okText: 'Continue',
        title: 'Apply Template',
        message:
          'This will replace the existing content. Do you wish to continue?',
        onOk: (resolve) => {
          this.doApply(messages)
          resolve()
        }
      })
    },
    doApply(messages) {
      this.languages.forEach((lang, index) => {
        const msg = messages[index]
        const pushType = this.PushTypes[this.messageType] || null
        if (!this.value.templateInfo) {
          this.$set(this.value, 'templateInfo', {})
        }
        this.$set(this.value.templateInfo, this.messageType, {
          templateId: msg.id,
          templateTitle: msg.title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        })
        if (pushType) {
          if (
            [MessageTypes.PUSH_IOS, MessageTypes.PUSH_ANDROID].includes(
              this.messageType
            )
          ) {
            this.value[pushType].payload = Object.values(
              msg.message.payload_add
            )
            if (msg.message.push_action.action === PushActions.INAPP) {
              // ignore In-App message
              this.value[pushType].defaultAction.type = 'none'
            } else {
              this.value[pushType].defaultAction.type =
                msg.message.push_action.action
              this.value[pushType].defaultAction.value =
                msg.message.push_action[msg.message.push_action.action]
            }
          }

          if (this.messageType === MessageTypes.PUSH_WEB) {
            this.value[pushType].iconUrl = msg.message.push_icon || ''
            this.value[pushType].url = msg.message.push_action.url || ''
          }

          this.value[pushType].pictureVariant =
            msg.message.push_picture_type || 'none'
          this.value[pushType].pictureUrl = msg.message.push_picture || ''
          this.value[pushType].messages[lang].text = msg.message.push_text || ''
          this.value[pushType].messages[lang].title =
            msg.message.push_title || ''
        }

        if (this.messageType === MessageTypes.SMS) {
          this.value.messages[lang].text = msg.message.text || ''
          this.value.messages[lang].fromName = msg.message.from || ''
          this.$set(
            this.value,
            'include_unsubscribe',
            Boolean(msg.message.sms_unsubscribe)
          )
        }
      })
      Toastr.success('Template applied successfully')
    }
  }
}
</script>
<style scoped lang="sass">
h3
  margin-top: 0
  color: #628DBF
  font-size: 18px

h5
  font-weight: bold

.template-text
  margin-bottom: 10px

.thumbnail
    padding: 0

.caption
    background-color: #ddd
    white-space: nowrap
    overflow: hidden
    display: block
    text-overflow: ellipsis
</style>
