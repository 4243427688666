<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Inbox Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">Arrived:</div>
        <div class="stats-bar-item-number">{{ arrivedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="push" />
        <div class="stats-bar-item-title">Sent:</div>
        <div class="stats-bar-item-number">
          {{ sentTotal | number }}
          ({{ sentTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mobile" />
        <div class="stats-bar-item-title">Delivered:</div>
        <div class="stats-bar-item-number">
          {{ deliveredTotal | number }}
          ({{ deliveredTotal | percentageOf(sentTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">Clicks:</div>
        <div class="stats-bar-item-number">
          {{ clicksTotal | number }}
          ({{ clicksTotal | percentageOf(deliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      title="How Many Profiles Have Arrived"
      title-class="action-color-messages"
      y-label="Number of Profiles"
    />
    <br />
    <br />

    <div class="btn-group pull-right">
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'numbers' }"
        @click="viewMode = 'numbers'"
      >
        Numbers
      </button>
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'percent' }"
        @click="viewMode = 'percent'"
      >
        Percent
      </button>
    </div>

    <keep-alive>
      <date-line-chart
        v-if="viewMode === 'numbers'"
        key="numbersChart"
        :chart-data="numbersChartData"
        title="Clicks"
        title-class="action-color-email"
        y-label="Number of Clicks"
      />

      <date-line-chart
        v-if="viewMode === 'percent'"
        key="percentChart"
        :y-axes-percent="true"
        :chart-data="percentChartData"
        title="Clicks"
        title-class="action-color-email"
        y-label="% Percentage"
      />
    </keep-alive>
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'

export default {
  components: {
    LineChart,
    DateLineChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  data() {
    return {
      viewMode: 'numbers'
    }
  },

  totalsMapping: {
    arrivedTotal: 'Inbox:trigger',
    sentTotal: 'Inbox:sent',
    bouncedTotal: 'Inbox:failed',
    clicksTotal: 'Inbox:opened'
  },

  statsMapping: {
    arrivedStats: 'Inbox:trigger',
    sentStats: 'Inbox:sent',
    clickStats: 'Inbox:opened'
  },

  computed: {
    deliveredTotal() {
      return this.sentTotal - this.bouncedTotal
    },

    clickedPercentStats() {
      const total = [],
        sentStats = this.sentStats,
        clickStats = this.clickStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((clickStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    numbersChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.clickStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.clickStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Clicks',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.clickedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.clickedPercentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Arrived',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: 'Sent',
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.sentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.sentStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
