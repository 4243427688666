<template>
  <span>
    a date before
    <span v-if="isDate(conditionArgs.value)" class="criteria-condition-args">
      '{{ conditionArgs.value | date('L') }}'
    </span>
    <span v-else class="invalid-text">missing date</span>
  </span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['conditionArgs'],

  methods: {
    isDate(value) {
      return value && moment(value).isValid()
    }
  }
}
</script>
