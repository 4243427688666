<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Email Stats:</label>
    <div class="stats-grid">
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="user" />
        <div class="stats-grid-item-title">Arrived:</div>
        <div class="stats-grid-item-number">{{ arrivedTotal | number }}</div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email" />
        <div class="stats-grid-item-title">Sent:</div>
        <div class="stats-grid-item-number">
          {{ sentTotal | number }}
          ({{ sentTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="inbox" />
        <div class="stats-grid-item-title">Delivered:</div>
        <div class="stats-grid-item-number">
          {{ deliveredTotal | number }}
          ({{ deliveredTotal | percentageOf(sentTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="arrow-redirect" />
        <div class="stats-grid-item-title">Bounced:</div>
        <div class="stats-grid-item-number">
          {{ bouncedTotal | number }}
          ({{ bouncedTotal | percentageOf(sentTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email-open" />
        <div class="stats-grid-item-title">Opens:</div>
        <div class="stats-grid-item-number">
          {{ uniqueOpensTotal | number }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ uniqueOpensTotal | percentageOf(deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="mouse" />
        <div class="stats-grid-item-title">Clicks:</div>
        <div class="stats-grid-item-number">
          {{ uniqueClicksTotal | number }}
          <span v-if="uniqueClicksTotal !== 'N/A'">
            ({{ uniqueClicksTotal | percentageOf(deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email-unsubscribe" />
        <div class="stats-grid-item-title">Unsubscribes:</div>
        <div class="stats-grid-item-number">
          {{ unsubscribesTotal | number }}
          ({{ unsubscribesTotal | percentageOf(deliveredTotal) }})
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'arrivedTotal',
    'sentTotal',
    'deliveredTotal',
    'bouncedTotal',
    'uniqueOpensTotal',
    'uniqueClicksTotal',
    'unsubscribesTotal'
  ]
}
</script>
