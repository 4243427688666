<template>
  <div>
    <div class="panel panel-primary">
      <div class="panel-heading">
        <button class="btn btn-xs btn-primary pull-right" @click="refresh">
          <icon v-tooltip="'Refresh'" glyph="refresh" />
        </button>
        <div class="panel-title">Audit Log</div>
      </div>
      <div class="rtable">
        <div class="rtable-heading">
          <div class="rtable-row">
            <div class="rtable-cell timestamp">Timestamp</div>
            <div class="rtable-cell user">User</div>
            <div class="rtable-cell message">Message</div>
          </div>
        </div>
        <div class="help-block" v-if="!logEntries.length">
          {{ loading ? 'Loading...' : 'No log entries to show' }}
        </div>
        <div class="rtable-body scrollbox" v-else v-infinite-scroll="loadMore" infinite-scroll-disabled="scrollDisabled"
          infinite-scroll-throttle-delay="50" infinite-scroll-distance="100">
          <div class="rtable-row" v-for="log in logEntries" :key="log.id">
            <div class="rtable-cell timestamp">
              {{ log.createdAt | date('L LTS') }}
            </div>
            <div class="rtable-cell user">{{ log.userIdentity }}</div>
            <div class="rtable-cell message">
              <span class="badge" v-if="log.occurs > 1">
                {{ log.occurs }} times
              </span>
              <component v-once :is="messageComponent(log.eventType)" :eventType="log.eventType"
                :metadata="log.metadata" :log="log" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-show="loading" class="loading-message">Loading More...</span>
  </div>
</template>
<script>
import MxaApi from '@/libs/MxaApi'

import infiniteScroll from 'vue-infinite-scroll'
import UnknownEventTypeMessage from './LogMessages/UnknownEventType'
import { isEqual } from 'lodash'

const limit = 50

export default {
  directives: {
    infiniteScroll
  },
  props: ['projectId'],

  data() {
    return {
      logEntries: [],
      loading: false,
      disableScroll: false,
      cursor: null
    }
  },

  computed: {
    scrollDisabled() {
      return this.disableScroll || this.loading
    }
  },

  created() {
    this.loadMore()
  },

  methods: {
    loadMore() {
      this.loading = true

      MxaApi.get(`projects/${this.projectId}/logs`, {
        params: {
          cursor: this.cursor,
          limit
        }
      }).then(({ data }) => {
        const rolledUpData = []
        let lastLogCompare, lastLog

        data.forEach((log) => {
          const logCompare = {
            userType: log.userType,
            userIdentity: log.userIdentity,
            eventType: log.eventType,
            workflowId: log.workflowId,
            actionId: log.actionId,
            metadata: log.metadata
          }

          if (isEqual(logCompare, lastLogCompare)) {
            lastLog.occurs++
            return
          }

          lastLogCompare = logCompare
          lastLog = log

          log.occurs = 1
          rolledUpData.push(log)
        })
        rolledUpData.forEach(Object.freeze)

        this.loading = false

        if (data.length === limit) {
          this.cursor = data[data.length - 1].id
          this.disableScroll = false
        } else {
          this.disableScroll = true
        }

        this.logEntries = this.logEntries.concat(rolledUpData)
      })
    },

    refresh() {
      this.logEntries = []
      this.disableScroll = false
      this.cursor = null
      this.loadMore()
    },

    messageComponent(eventType) {
      const component = eventType
        .replace(/^(.)|\.(.)/g, ($1) => $1.toUpperCase())
        .replace(/\./g, '')

      try {
        return require('./LogMessages/' + component).default
      } catch (e) {}

      return UnknownEventTypeMessage
    }
  }
}
</script>
<style lang="sass" scoped>
.message ::v-deep .icon
  margin-right: 5px

.loading-message
  position: absolute
  bottom: 20px
  right: 25px

.panel
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  margin: 15px 15px 50px
  display: flex
  flex-direction: column
  flex: 1
  overflow: hidden

.rtable
  flex: 1
  display: flex
  flex-direction: column
  border-top: 1px solid #ddd
  overflow-y: scroll
  .help-block
    padding: 8px

  .rtable-heading
    .rtable-row
      display: flex
      .rtable-cell
        background-color: #ECECEC
        font-weight: bold
        border-bottom: 1px solid #ddd
        overflow: hidden
        text-overflow: ellipsis
        padding: 8px

  .rtable-body
    flex: 1
    .rtable-row
      display: flex
      border-top: 1px solid #ddd
      .rtable-cell
        overflow: hidden
        text-overflow: ellipsis
        padding: 8px

.rtable
  .timestamp
    width: 180px

  .user
    width: 350px

  .message
    flex: 1

    .badge
      float: right
</style>
