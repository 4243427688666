<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Split Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">Arrived:</div>
        <div class="stats-bar-item-number">{{ arrivedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #76a9ca; color: #fff"
        >
          A
        </div>
        <div class="stats-bar-item-title">Number to A:</div>
        <div class="stats-bar-item-number">
          {{ aTotal | number }}
          ({{ aTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #b4d688; color: #fff"
        >
          B
        </div>
        <div class="stats-bar-item-title">Number to B:</div>
        <div class="stats-bar-item-number">
          {{ bTotal | number }}
          ({{ bTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div v-if="cTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #ee78bf; color: #fff"
        >
          C
        </div>
        <div class="stats-bar-item-title">Number to C:</div>
        <div class="stats-bar-item-number">
          {{ cTotal | number }}
          ({{ cTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div v-if="dTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #696d8d; color: #fff"
        >
          D
        </div>
        <div class="stats-bar-item-title">Number to D:</div>
        <div class="stats-bar-item-number">
          {{ dTotal | number }}
          ({{ dTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
      <div v-if="eTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #f4777c; color: #fff"
        >
          E
        </div>
        <div class="stats-bar-item-title">Number to E:</div>
        <div class="stats-bar-item-number">
          {{ eTotal | number }}
          ({{ eTotal | percentageOf(arrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      title="How Many Profiles Have Arrived"
      title-class="action-color-decision"
      y-label="Number of Profiles"
      style="margin-bottom: 30px"
    />
    <bar-chart
      title="Where Profiles Branched To"
      :chart-data="decisionChartData"
      title-class="action-color-decision"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'
import BarChart from './Charts/StackedBar'

export default {
  components: {
    LineChart,
    BarChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  computed: {
    arrivedTotal() {
      return this.aTotal + this.bTotal + this.cTotal + this.dTotal + this.eTotal
    },

    aTotal() {
      return this.getTotal('Split:a')
    },

    bTotal() {
      return this.getTotal('Split:b')
    },

    cTotal() {
      return this.getTotal('Split:c')
    },

    dTotal() {
      return this.getTotal('Split:d')
    },

    eTotal() {
      return this.getTotal('Split:e')
    },

    aStats() {
      return this.getStats('Split:a')
    },

    bStats() {
      return this.getStats('Split:b')
    },

    cStats() {
      return this.getStats('Split:c')
    },

    dStats() {
      return this.getStats('Split:d')
    },

    eStats() {
      return this.getStats('Split:e')
    },

    arrivedStats() {
      return this.getCombinedStats(
        'Split:a',
        'Split:b',
        'Split:c',
        'Split:d',
        'Split:e'
      )
    },

    aPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitA = this.aStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitA[idx] / entered) * 100) || 0)
      )

      return total
    },

    bPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitB = this.bStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitB[idx] / entered) * 100) || 0)
      )

      return total
    },

    cPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitC = this.cStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitC[idx] / entered) * 100) || 0)
      )

      return total
    },

    dPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitD = this.dStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitD[idx] / entered) * 100) || 0)
      )

      return total
    },

    ePercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitE = this.eStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitE[idx] / entered) * 100) || 0)
      )

      return total
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Arrived',
            backgroundColor: 'RGBA(245, 177, 95, .2)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(245, 177, 95, 1)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            spanGaps: true
          }
        ]
      }
    },

    decisionChartData() {
      const dataSet = [
        {
          label: 'A',
          backgroundColor: 'RGBA(118, 169, 202, .2)',
          borderColor: 'RGBA(118, 169, 202, 1)',
          borderWidth: 1,
          data: this.aPercentStats
        },
        {
          label: 'B',
          backgroundColor: 'RGBA(180, 214, 136, .2)',
          borderColor: 'RGBA(180, 214, 136, 1)',
          borderWidth: 1,
          data: this.bPercentStats
        }
      ]

      if (this.cTotal) {
        dataSet.push({
          label: 'C',
          backgroundColor: 'RGBA(238, 120, 191, .2)',
          borderColor: 'RGBA(238, 120, 191, 1))',
          borderWidth: 1,
          data: this.cPercentStats
        })
      }

      if (this.dTotal) {
        dataSet.push({
          label: 'D',
          backgroundColor: 'RGBA(105, 109, 141, .2)',
          borderColor: 'RGBA(105, 109, 141, 1)',
          borderWidth: 1,
          data: this.dPercentStats
        })
      }

      if (this.eTotal) {
        dataSet.push({
          label: 'E',
          backgroundColor: 'RGBA(244, 119, 124, .2)',
          borderColor: 'RGBA(244, 119, 124, 1)',
          borderWidth: 1,
          data: this.ePercentStats
        })
      }

      return {
        labels: this.dateRange,
        datasets: dataSet
      }
    }
  }
}
</script>
