<template>
  <div class="modal fade modal-primary" tabindex="-1">
    <div class="modal-dialog" :class="modalSize" role="document">
      <div class="modal-content">
        <slot name="modal-header">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">
              <slot name="title">{{ title }}</slot>
            </h4>
          </div>
        </slot>

        <slot name="modal-body">
          <div class="modal-body">
            <slot></slot>
          </div>
        </slot>

        <slot name="modal-footer">
          <div class="modal-footer">
            <button
              v-if="showCancelButton"
              :disabled="disabled"
              type="button"
              class="btn btn-default"
              @click="hide"
            >
              {{ cancelText }}
            </button>
            <button
              v-if="showOkButton"
              :disabled="disabled"
              type="button"
              class="btn btn-primary"
              @click="$emit('ok')"
            >
              {{ okText }}
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'

export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    showOkButton: {
      type: Boolean,
      default: true
    },
    okText: {
      type: String,
      default: 'OK'
    },
    okOnEnter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    modalSize() {
      if (this.size === 'large') {
        return 'modal-lg'
      } else if (this.size === 'small') {
        return 'modal-sm'
      }

      return null
    }
  },

  mounted() {
    document.addEventListener('keypress', this.onKeyPress)

    $(this.$el).modal()

    // autofocus helper
    $(this.$el).on('shown.bs.modal', function () {
      $(this).find('[autofocus]').focus()
    })

    $(this.$el).on('hide.bs.modal', (e) => {
      if (this.forceClose) return
      if (!this.closeable) e.preventDefault()
    })

    $(this.$el).on('hidden.bs.modal', () => this.$root.$destroy())
  },

  destroyed() {
    document.removeEventListener('keypress', this.onKeyPress)

    this.forceClose = true
    $(this.$el).modal('hide')
    $(this.$el).off()
  },

  methods: {
    onKeyPress(e) {
      if (e.which === 13 && this.okOnEnter && !this.disabled) {
        this.$emit('ok')
      }
    },

    hide() {
      $(this.$el).modal('hide')
    },

    close() {
      this.forceClose = true
      $(this.$el).modal('hide')
    }
  }
}
</script>
