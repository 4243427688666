<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Arrived'" glyph="user" />
      {{ entered | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Yes'" glyph="tick-box" />
      {{ stats['Decision:evaluatedYes'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'No'" glyph="cross-circle" />
      {{ stats['Decision:evaluatedNo'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats'],

  computed: {
    entered() {
      return (
        (Number(this.stats['Decision:evaluatedYes']) || 0) +
        (Number(this.stats['Decision:evaluatedNo']) || 0)
      )
    }
  }
}
</script>
