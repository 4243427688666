var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar sidebar-lg"},[_c('div',{staticClass:"sidebar-header"},[_c('icon',{staticClass:"sidebar-icon",attrs:{"glyph":"workflow"}}),_vm._v(" "),_c('div',{staticClass:"sidebar-title"},[_vm._v("Workflow Reporting")]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],1),_vm._v(" "),_c('div',{staticClass:"sidebar-body"},[_c('tabs',{staticClass:"my-padding",attrs:{"nav-class":"nav-side","starting-tab":"quick-stats","full-height":true}},[_c('tab',{attrs:{"position":1,"id":"quick-stats","icon":"bar-chart","keep-alive":true}},[_c('workflow-stats',_vm._b({},'workflow-stats',{
            startedTotal: _vm.startedTotal,
            completedTotal: _vm.completedTotal,
            waitingTotal: _vm.waitingTotal,
            emailArrivedTotal: _vm.emailArrivedTotal,
            emailSentTotal: _vm.emailSentTotal,
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailBouncesTotal: _vm.emailBouncesTotal,
            emailUniqueOpensTotal: _vm.emailUniqueOpensTotal,
            emailUniqueClicksTotal: _vm.emailUniqueClicksTotal,
            emailUnsubscribesTotal: _vm.emailUnsubscribesTotal,
            smsArrivedTotal: _vm.smsArrivedTotal,
            smsSentTotal: _vm.smsSentTotal,
            pushArrivedTotal: _vm.pushArrivedTotal,
            pushSentTotal: _vm.pushSentTotal,
            pushDeliveredTotal: _vm.pushDeliveredTotal,
            pushClickedTotal: _vm.pushClickedTotal,
            channels: _vm.channels,
            workflow: _vm.workflow
          },false))],1),_vm._v(" "),_c('tab',{attrs:{"position":2,"id":"started-completed","icon":"user","keep-alive":true}},[_c('workflow-started',_vm._b({},'workflow-started',{
            startedTotal: _vm.startedTotal,
            completedTotal: _vm.completedTotal,
            dateRange: _vm.dateRange,
            startedStats: _vm.startedStats,
            completedStats: _vm.completedStats
          },false))],1),_vm._v(" "),(_vm.channels.push)?_c('tab',{attrs:{"position":3,"id":"push-stats","icon":"push","keep-alive":true}},[_c('push-stats',_vm._b({},'push-stats',{
            dateRange: _vm.dateRange,
            pushArrivedTotal: _vm.pushArrivedTotal,
            pushSentTotal: _vm.pushSentTotal,
            pushDeliveredTotal: _vm.pushDeliveredTotal,
            pushClickedTotal: _vm.pushClickedTotal,
            pushSentStats: _vm.pushSentStats,
            pushClickedStats: _vm.pushClickedStats
          },false))],1):_vm._e(),_vm._v(" "),(_vm.channels.email)?_c('tab',{attrs:{"position":4,"id":"emails-sent","icon":"email","keep-alive":true}},[_c('email-delivery',_vm._b({},'email-delivery',{
            emailArrivedTotal: _vm.emailArrivedTotal,
            emailSentTotal: _vm.emailSentTotal,
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailBouncesTotal: _vm.emailBouncesTotal,
            dateRange: _vm.dateRange,
            emailSentStats: _vm.emailSentStats
          },false))],1):_vm._e(),_vm._v(" "),(_vm.channels.email)?_c('tab',{attrs:{"position":5,"id":"emails-engagement","icon":"email-open","keep-alive":true}},[_c('email-engagement',_vm._b({},'email-engagement',{
            emailDeliveredTotal: _vm.emailDeliveredTotal,
            emailUniqueOpensTotal: _vm.emailUniqueOpensTotal,
            emailUniqueClicksTotal: _vm.emailUniqueClicksTotal,
            emailUnsubscribesTotal: _vm.emailUnsubscribesTotal,
            dateRange: _vm.dateRange,
            emailSentStats: _vm.emailSentStats,
            emailUniqueOpensStats: _vm.emailUniqueOpensStats,
            emailUniqueClicksStats: _vm.emailUniqueClicksStats,
            emailUnsubscribesStats: _vm.emailUnsubscribesStats,
            workflow: _vm.workflow
          },false))],1):_vm._e(),_vm._v(" "),(_vm.channels.sms)?_c('tab',{attrs:{"position":6,"id":"sms-delivery","icon":"sms","keep-alive":true}},[_c('sms-delivery',_vm._b({},'sms-delivery',{
            smsArrivedTotal: _vm.smsArrivedTotal,
            smsSentTotal: _vm.smsSentTotal,
            dateRange: _vm.dateRange,
            smsSentStats: _vm.smsSentStats,
            smsUnsubscribesStats: _vm.smsUnsubscribesStats
          },false))],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }