import queryString from 'query-string'
import jwtDecode from 'jwt-decode'

import MxaApi from '@/libs/MxaApi'
import XpApi from '@/libs/XpApi'
import appConfig from '@/config/config'
import router from '@/providers/Router'
import store from '@/stores/store'

MxaApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      auth.logout()
    }

    return Promise.reject(error.response)
  }
)

class SilentAuthenticator {
  constructor() {
    this._eventListener = this._eventListener.bind(this)
    this._timeoutHandler = this._timeoutHandler.bind(this)
  }

  authorize(projectId) {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject

      this.iframe = document.createElement('iframe')
      this.iframe.style.display = 'none'

      window.addEventListener('message', this._eventListener, false)

      document.body.appendChild(this.iframe)

      const redirectUri = encodeURIComponent(
        `${window.location.origin}/auth-renew.html`
      )
      if (document.referrer) {
        // Remove the trailing forward slash from the document referrer and store in app store
        store.dispatch('setXpBaseUrl', document.referrer.replace(/\/$/, ''))
      } else {
        // Fallback to the appConfig when the document referrer is not available
        store.dispatch('setXpBaseUrl', appConfig.xpBaseUrl)
      }
      this.iframe.src = `${store.getters.getXpBaseUrl}/auth/rest?redirect_uri=${redirectUri}&project_id=${projectId}&non_interactive=true`

      this.timeoutId = setTimeout(this._timeoutHandler, 3 * 1000)
    })
  }

  _eventListener(event) {
    if (
      !(
        event.origin === window.location.origin &&
        event.data.type === 'authorization_response'
      )
    ) {
      return
    }

    this._cleanUp()

    const params = queryString.parse(event.data.data)
    if (params.error) {
      this.reject(params.error)
    } else {
      this.resolve(params)
    }
  }

  _timeoutHandler() {
    this._cleanUp()
    this.reject('timeout')
  }

  _cleanUp() {
    this.timeoutId && clearTimeout(this.timeoutId)
    this.iframe && this.iframe.parentElement.removeChild(this.iframe)
    window.removeEventListener('message', this._eventListener, false)
  }
}

class Auth {
  constructor() {
    setInterval(this.checkToken.bind(this), 2 * 1000)
  }

  authorize(projectId, returnPath) {
    projectId = Number(projectId)

    return new Promise(async (resolve) => {
      if (this.projectId === projectId && !this.hasTokenExpired()) {
        return resolve()
      }

      this.refreshingToken = true
      new SilentAuthenticator()
        .authorize(projectId)
        .then((params) => {
          this.setToken(params.access_token, params.id_token)
          this.refreshingToken = false
          resolve()
        })
        .catch(() => {
          const encodedReturnPath = encodeURIComponent(returnPath)
          const redirectUri = encodeURIComponent(
            `${window.location.origin}/login/callback?returnPath=${encodedReturnPath}`
          )
          window.location = `${store.getters.getXpBaseUrl}/auth/rest?redirect_uri=${redirectUri}&project_id=${projectId}`
        })
    })
  }

  handleAuthentication() {
    const hashParams = queryString.parse(window.location.hash.substring(1))

    if (hashParams.error) {
      // @TODO error handling
      return
    }

    this.setToken(hashParams.access_token, hashParams.id_token)

    const queryParams = queryString.parse(window.location.search.substring(1))

    const returnPath = queryParams.returnPath || {
      name: 'Project',
      params: {
        projectId: jwtDecode(hashParams.access_token).project_id
      }
    }

    router.replace(returnPath)
  }

  setToken(accessToken, idToken) {
    MxaApi.defaults.headers['X-Auth-Token'] = accessToken
    XpApi.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    const accessTokenPayload = jwtDecode(accessToken)
    const idTokenPayload = jwtDecode(idToken)

    this.expiry = accessTokenPayload.exp
    this.projectId = Number(accessTokenPayload.project_id)

    store.dispatch('setUser', {
      email: idTokenPayload.email,
      name: idTokenPayload.name,
      language: idTokenPayload.language
    })

    store.dispatch('setProject', {
      id: idTokenPayload.project_id,
      name: idTokenPayload.project_name,
      projectChannels: idTokenPayload.project_channels,
      projectFeatures: idTokenPayload.project_features,
      icon: idTokenPayload.project_icon,
      projectTimezone: idTokenPayload.project_timezone,
      domains: idTokenPayload['provider_domains']
        ? idTokenPayload['provider_domains']
        : []
    })
  }

  hasTokenExpired() {
    // 10 minutes before the token expires
    return !(this.expiry && this.expiry - 60 * 10 > Date.now() / 1000)
  }

  checkToken() {
    if (this.projectId && this.hasTokenExpired() && !this.refreshingToken) {
      this.authorize(this.projectId)
    }
  }

  logout() {
    window.location = `${store.getters.getXpBaseUrl}/logout`
  }
}

const auth = new Auth()

export default auth
