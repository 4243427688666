<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Sent'" glyph="sms" />
      {{ stats['Sms:sent'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
