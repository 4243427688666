<template>
  <span>
    in the next
    <span v-if="isValid" class="criteria-condition-args">
      {{ conditionArgs.amount }}
      {{ translations[conditionArgs.operator] || '' }}
    </span>
    <span v-else class="invalid-text">missing data</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  data() {
    return {
      translations: {
        days: 'days',
        hours: 'hours',
        minutes: 'minutes'
      }
    }
  },

  computed: {
    isValid() {
      return !(
        isNaN(this.conditionArgs.amount) || this.conditionArgs.amount === ''
      )
    }
  }
}
</script>
