<template>
  <div>
    <span class="current-criteria__criteria__options__type">Message</span>
    <span v-if="isFieldValid">
      <span v-if="isSystemField">{{ field }}</span>
      <span v-else class="criteria-field-name">'{{ fieldText }}'</span>
    </span>
    <span v-else class="invalid-text">missing message</span>
    <span>{{ negatorText }}</span>
    <div :is="conditionCmp" :condition-args="conditionArgs"></div>
  </div>
</template>
<script>
import MissingCondition from './MissingCondition'

export default {
  inject: ['workflowApi'],

  props: ['field', 'negator', 'condition', 'conditionArgs'],

  computed: {
    fieldText() {
      let message = this.getMessage(this.field)
      if (message) {
        return message.name
      }
      return 'Missing message'
    },

    negatorText: function () {
      return !this.negator ? 'is' : 'is not'
    },

    isSystemField() {
      return this.field === 'first' || this.field === 'last'
    },

    isFieldValid() {
      switch (true) {
        case this.field === 'first':
        case this.field === 'last':
        case this.getMessage(this.field) !== undefined:
          return true

        default:
          return false
      }
    },

    conditionCmp() {
      try {
        return require(`./Message/${this.condition}`).default
      } catch (e) {}

      return MissingCondition
    }
  },

  methods: {
    getMessage(actionId) {
      return this.workflowApi.getAction(actionId)
    }
  }
}
</script>
