<template>
  <span>
    anniversary
    <span v-if="isValid" class="criteria-condition-args">
      <span v-if="conditionArgs.operator !== 'user_calendar_today'">
        {{ conditionArgs.amount }}
      </span>
      {{ translations[conditionArgs.operator] || '' }}
    </span>
    <span v-else class="invalid-text">missing data</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  data() {
    return {
      translations: {
        user_calendar_today: 'today (by user timezone)',
        user_calendar_days_ago: 'calendar days ago (by user timezone)',
        user_calendar_days_ahead: 'calendar days ahead (by user timezone)'
      }
    }
  },

  computed: {
    isValid() {
      if (this.conditionArgs.operator === 'user_calendar_today') {
        return true
      }
      return !(
        isNaN(this.conditionArgs.amount) || this.conditionArgs.amount === ''
      )
    }
  }
}
</script>
