import { render, staticRenderFns } from "./Decision.vue?vue&type=template&id=4ec3c3a6&"
import script from "./Decision.vue?vue&type=script&lang=js&"
export * from "./Decision.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports