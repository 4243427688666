<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>End Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">Arrived:</div>
        <div class="stats-bar-item-number">{{ arrivedTotal | number }}</div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      title="How Many Profiles Have Arrived"
      title-class="action-color-end"
      y-label="Number of Profiles"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'

export default {
  components: {
    LineChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  computed: {
    arrivedTotal() {
      return this.getTotal('End:completed')
    },

    arrivedStats() {
      return this.getStats('End:completed')
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Arrived',
            backgroundColor: 'RGBA(85, 83, 83, .2)',
            borderColor: 'RGBA(85, 83, 83, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(85, 83, 83, 1)',
            borderColor: 'RGBA(85, 83, 83, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
