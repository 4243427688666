<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Arrived'" glyph="user" />
      {{ stats['Push:trigger'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Sent'" glyph="push" />
      {{ stats['Push:sent'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Opened'" glyph="mouse" />
      {{ stats['Push:openedUnique'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
