<template>
  <div style="padding: 20px">
    <label>Email Delivery Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email" />
        <div class="stats-bar-item-title">Sent:</div>
        <div class="stats-bar-item-number">
          {{ emailSentTotal | number }}
          ({{ emailSentTotal | percentageOf(emailArrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="inbox" />
        <div class="stats-bar-item-title">Delivered:</div>
        <div class="stats-bar-item-number">
          {{ emailDeliveredTotal | number }}
          ({{ emailDeliveredTotal | percentageOf(emailSentTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="arrow-redirect" />
        <div class="stats-bar-item-title">Bounced:</div>
        <div class="stats-bar-item-number">
          {{ emailBouncesTotal | number }}
          ({{ emailBouncesTotal | percentageOf(emailBouncesTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      :chart-data="chartData"
      title="Total Number of Emails Sent"
      title-class="action-color-email"
      yLabel="Number of Profiles"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  props: [
    'emailArrivedTotal',
    'emailSentTotal',
    'emailDeliveredTotal',
    'emailBouncesTotal',

    'dateRange',
    'emailSentStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  computed: {
    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Sent',
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.emailSentStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Trend Line',
            data: this.getTrendLine(this.emailSentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
