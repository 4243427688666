<template>
  <div>
    <input
      ref="minVal"
      v-model="min"
      class="form-control form-number"
      placeholder="Insert Number"
      type="number"
      @input="onInput"
    />
    <span class="text-and">and</span>
    <input
      ref="maxVal"
      v-model="max"
      class="form-control form-number"
      placeholder="Insert Number"
      type="number"
      @input="onInput"
    />
  </div>
</template>
<script>
export default {
  props: ['value'],

  data() {
    return {
      min: this.value.min || '',
      max: this.value.max || ''
    }
  },

  methods: {
    onInput() {
      this.$emit('input', {
        min: this.min,
        max: this.max
      })
    }
  }
}
</script>
