var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"20px"}},[_c('button',{staticClass:"btn btn-default btn-block",attrs:{"type":"button"},on:{"click":_vm.openEditor}},[_vm._v("\n    "+_vm._s(!_vm.isAvailable ? 'Preview' : 'Edit')+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Inbox Type:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value"},[_vm._v(_vm._s(_vm.formattedInboxType))]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Default Language:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value"},[_vm._v(_vm._s(_vm._f("langName")(_vm.config.defaultLang)))]),_vm._v(" "),(_vm.isExclusionEnabled)?_c('div',[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Exclusion category:")]),_vm._v(" "),(
            !_vm.action.config.exclusion || _vm.action.config.exclusion.check === false
          )?_c('div',{staticClass:"inbox-prop-value"},[_vm._v("\n          Not Enabled\n        ")]):(
            (_vm.action.config.exclusion && _vm.action.config.exclusion.check) ||
            _vm.displayExclusionError
          )?_c('div',{staticClass:"inbox-prop-value",class:{
            'invalid-text':
              _vm.displayExclusionError ||
              _vm.$v.action.config.exclusion.category.$invalid
          }},[_vm._v("\n          "+_vm._s(_vm.exclusionCategoryName)+"\n          "),(
              _vm.displayExclusionError ||
              !_vm.$v.action.config.exclusion.category.required
            )?[_vm._v("\n            missing exclusion category\n          ")]:_vm._e()],2):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.config.languages.length > 1)?[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Additional Languages:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value"},[_vm._v(_vm._s(_vm.formattedLanguage))])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Default Action:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value"},[_vm._v(_vm._s(_vm.formattedDefaultAction))]),_vm._v(" "),(_vm.config.push_action.action === 'url')?[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("URL:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value",class:{
            'invalid-text': _vm.$v.action.config.push_action.url.$invalid
          }},[_vm._v("\n          "+_vm._s(_vm.config.push_action.url)+"\n          "),(!_vm.$v.action.config.push_action.url.required)?[_vm._v("\n            missing URL\n          ")]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Open in a new tab:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value"},[_vm._v("\n          "+_vm._s(_vm.config.push_action.url_blank ? 'Yes' : 'No')+"\n        ")])]:_vm._e(),_vm._v(" "),(_vm.config.push_action.action === 'deeplink')?[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("URL:")]),_vm._v(" "),_c('div',{staticClass:"inbox-prop-value",class:{
            'invalid-text': _vm.$v.action.config.push_action.deeplink.$invalid
          }},[_vm._v("\n          "+_vm._s(_vm.config.push_action.deeplink)+"\n          "),(!_vm.$v.action.config.push_action.deeplink.required)?[_vm._v("\n            missing Deeplink\n          ")]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.config.payload_add.length > 0)?[_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Payload:")]),_vm._v(" "),_vm._l((_vm.config.payload_add),function(payload){return _c('div',[_c('div',{staticClass:"inbox-prop-value"},[_vm._v("\n            { "+_vm._s(payload.key)+": "+_vm._s(payload.value)+" }\n          ")])])})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"inbox-prop-name"},[_vm._v("Persist content in inbox:")]),_vm._v(" "),(_vm.config.persist_content === 'until')?_c('div',{staticClass:"inbox-prop-value"},[_vm._v("\n        "+_vm._s(_vm.config.persist_content_until_date)+"\n        "),(_vm.config.persist_content_until_date_timezone === 'project')?_c('span',[_vm._v("\n          "+_vm._s(_vm.config.project_timezone)+"\n        ")]):(_vm.config.persist_content_until_date_timezone === 'profile')?_c('span',[_vm._v("\n          in the timezone of the profile.\n        ")]):_c('span',[_vm._v("\n          "+_vm._s(_vm.config.persist_content_until_date_timezone)+"\n        ")])]):(_vm.config.persist_content === 'for')?_c('div',{staticClass:"inbox-prop-value"},[_vm._v("\n        For "+_vm._s(_vm.config.persist_content_for_n)+"\n        "+_vm._s(_vm.config.persist_content_for_unit)+"\n        "),(_vm.config.persist_content_for_n > 1)?_c('span',[_vm._v("s")]):_vm._e()]):_c('div',{staticClass:"inbox-prop-value"},[_vm._v("Do not remove")])],2)]),_vm._v(" "),_c('div',{staticClass:"preview-container"},[_c('inbox-message-preview',{attrs:{"message":_vm.formattedMessage,"message-type":_vm.config.inbox_type,"message-style":_vm.messageStyles,"global-styles":{}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }