<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>Delay Stats:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="hand" />
        <div class="stats-bar-item-title">Total Delayed:</div>
        <div class="stats-bar-item-number">{{ delayedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="clock" />
        <div class="stats-bar-item-title">Currently Waiting:</div>
        <div class="stats-bar-item-number">{{ waitingTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="cross-circle" />
        <div class="stats-bar-item-title">Total Ended Early:</div>
        <div class="stats-bar-item-number">{{ earlyCancelTotal | number }}</div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      title="How Many Profiles Have Been Delayed"
      title-class="action-color-delay"
      y-label="Number of Profiles"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'

export default {
  components: {
    LineChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  computed: {
    delayedTotal() {
      return this.getTotal('Delay:delayed')
    },

    resumedTotal() {
      return this.getTotal('Delay:resumed')
    },

    cancelledTotal() {
      return this.getTotal('Delay:cancelled')
    },

    earlyCancelTotal() {
      return this.getTotal('Delay:earlyCancel')
    },

    waitingTotal() {
      return (
        this.delayedTotal -
        this.resumedTotal -
        this.cancelledTotal -
        this.earlyCancelTotal
      )
    },

    delayedStats() {
      return this.getStats('Delay:delayed')
    },

    earlyCancelledStats() {
      return this.getStats('Delay:earlyCancel')
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: 'Total Delayed',
            backgroundColor: 'RGBA(221, 56, 88, .2)',
            borderColor: 'RGBA(221, 56, 88, 1)',
            borderWidth: 1,
            data: this.delayedStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Delayed Trend Line',
            data: this.getTrendLine(this.delayedStats),
            backgroundColor: 'RGBA(221, 56, 88, 1)',
            borderColor: 'RGBA(221, 56, 88, 1)',
            spanGaps: true
          },
          {
            label: 'Early Cancelled Total',
            backgroundColor: 'RGBA(0, 0, 255, .2)',
            borderColor: 'RGBA(0, 0, 255, 1)',
            borderWidth: 1,
            data: this.earlyCancelledStats
          },
          {
            tooltip: false,
            fill: false,
            label: 'Early Cancelled Trend Line',
            data: this.getTrendLine(this.earlyCancelledStats),
            backgroundColor: 'RGBA(0, 0, 255, 1)',
            borderColor: 'RGBA(0, 0, 255, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
<style scoped>
.stats-bar-item {
  margin-right: 20px;
}
</style>
